import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = (theme) => {
  return {
    column: {
      minWidth: '56px',
      minHeight: '33px',
      justifyContent: 'center',
      fontSize: theme.typography.fontSize * 1.2,
      border: '1px solid',
      padding: '4px',
      display: 'flex',
    },
    bid: {
      color: theme.palette.text.primary,
      fontSize: 16,
      backgroundColor: theme.palette.background.bidBackground,
      borderColor: theme.palette.bidBorder,
      opacity: 1,
    },
    offer: {
      color: theme.palette.text.primary,
      fontSize: 16,
      backgroundColor: theme.palette.background.offerBackground,
      borderColor: theme.palette.offerBorder,
      opacity: 1,
    },
    suspended: {
      color: theme.palette.amber_500,
    },

    updated: {
      background: `transparent`,
      animationName: '$updated',
      animationDuration: '2000ms',
      animationTimingFunction: 'ease-in-out',
    },

    '@keyframes updated': {
      '0%': { backgroundColor: theme.palette.amber_A400, opacity: 0.9 },
      '25%': { backgroundColor: theme.palette.teal_A400, opacity: 0.7 },
      '50%': { backgroundColor: theme.palette.amber_A400, opacity: 0.9 },
      '75%': { backgroundColor: theme.palette.lightBlue_A400, opacity: 0.7 },
      '100%': { backgroundColor: theme.palette.amber_A400, opacity: 0.9 },
    },
  };
};

const Cell = ({ value, type, status, classes }) => {
  //if (!value) return <td />;
  const [classNames, setClassNames] = useState('transparent');

  useEffect(() => {
    setClassNames(
      clsx({
        [classes.column]: true,
        [classes.bid]: type === 'bidPrice' || type === 'bidVolume',
        [classes.offer]: type === 'offerPrice' || type === 'offerVolume',
        [classes.suspended]: status === 'SUSPENDED',
        [classes.updated]: value,
      }),
    );

    const handler = setTimeout(() => {
      setClassNames(
        clsx({
          [classes.column]: true,
          [classes.bid]: type === 'bidPrice' || type === 'bidVolume',
          [classes.offer]: type === 'offerPrice' || type === 'offerVolume',
          [classes.suspended]: status === 'SUSPENDED',
        }),
      );
    }, 2500);

    return function cleanup() {
      clearTimeout(handler);
    };
  }, [type, value, status]);

  return (
    <React.Fragment>
      <div className={classNames}>{value}</div>
    </React.Fragment>
  );
};

Cell.propTypes = {
  classes: PropTypes.shape({
    column: PropTypes.string.isRequired,
    bid: PropTypes.string.isRequired,
    offer: PropTypes.string.isRequired,
    updated: PropTypes.string.isRequired,
    '@keyframes updated': PropTypes.string,
  }).isRequired,
  value: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
};

export default withStyles(styles)(Cell);
