import { createSelector } from 'reselect';
import { NAME } from '../constants';

import auth from '../../auth';
import organisations from '../../organisations';
import users from '../../users';
import orders from '../../orders';
import * as rfqsSelectors from '../../rfqs/selectors';
import { isObject } from '../../../commons/utils/functions';

const getAllObjectProp = (state) => {
  const items = state[NAME].byId;
  return items;
};
export const getAllObject = createSelector(getAllObjectProp, (items) => items);
export const getAll = createSelector(getAllObject, (items) => Object.values(items));

const getIdsProp = (state) => {
  const items = state[NAME].allIds;
  return items;
};
export const getIds = createSelector(getIdsProp, (ids) => ids);

const getByIdProp = (state, id) => {
  const item = state[NAME].byId[id];
  return item;
};
export const getById = createSelector(getByIdProp, (item) => item);

const isTradeDetailsOpenProp = (state) => {
  const form = state[NAME].tradeDetails;
  return form.open;
};
export const isTradeDetailsOpen = createSelector(isTradeDetailsOpenProp, (items) => items);

const isPostTradeFormOpenProp = (state) => {
  const form = state[NAME].postTradeForm;
  return form.open;
};
export const isPostTradeFormOpen = createSelector(isPostTradeFormOpenProp, (items) => items);

const getTradeDetailsTitleProp = (state) => {
  const form = state[NAME].tradeDetails;
  return form.title;
};
export const getTradeDetailsTitle = createSelector(getTradeDetailsTitleProp, (items) => items);

const getPostTradeFormProp = (state) => {
  const form = state[NAME].postTradeForm;
  return form;
};
export const getPostTradeForm = createSelector(getPostTradeFormProp, (items) => items);

const getPostTradeFormDataProp = (state) => {
  const form = state[NAME].postTradeForm;
  return form.data;
};
export const getPostTradeFormData = createSelector(getPostTradeFormDataProp, (items) => items);

const isEndRfqPromptOpenProp = (state) => {
  const form = state[NAME].endRfqPrompt;
  return form.open;
};
export const isEndRfqPromptOpen = createSelector(isEndRfqPromptOpenProp, (items) => items);

const getEndRfqPromptRfqIdProp = (state) => {
  const form = state[NAME].endRfqPrompt;
  return form.rfqId;
};
export const getEndRfqPromptRfqId = createSelector(getEndRfqPromptRfqIdProp, (items) => items);

const getTradeIdsForNotificationProp = (state) => {
  const items = state[NAME].tradeDetails.tradeIds;
  return items;
};
export const getTradeIdsForNotification = createSelector(
  getTradeIdsForNotificationProp,
  (items) => items,
);

export const getTradesForNotification = createSelector(
  getAllObject,
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,
  getTradeIdsForNotification,
  orders.selectors.getAllObject,
  rfqsSelectors.getAllObject,
  (trades, user, users, orgs, tradeIds, ordersById, rfqsById) => {
    const myOrgId = user.organisationId;
    const formattedTrades = [];

    const getDirection = (direction, buyOrgId) => {
      if (buyOrgId === myOrgId) {
        return direction;
      }
      if (direction === 'SELL') {
        return 'BUY';
      }
      return 'SELL';
    };

    const getFullOrg = (id) => {
      const organisation = {};
      if (typeof orgs[id] !== 'undefined') {
        organisation.id = id;
        organisation.name = orgs[id].name;
        organisation.shortName = orgs[id].shortName;
      }
      return organisation;
    };

    const getFullUser = (id) => {
      const fulluser = {};
      if (typeof users[id] !== 'undefined') {
        fulluser.id = id;
        fulluser.name = `${users[id].firstName} ${users[id].lastName}`;
      }
      return fulluser;
    };

    const getCounterparty = (sellOrgId, buyOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId === myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    const getOwn = (sellOrgId, buyOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId !== myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    const getComments = (buyOrgId, buyOrderId, sellOrderId, ownComments) => {
      if (ownComments) {
        if (buyOrgId === myOrgId) {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        } else {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        }
      } else {
        if (buyOrgId === myOrgId) {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        } else {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        }
      }
    };
    const getData = (buyOrgId, ownData, trade) => {
      if (ownData) {
        if (buyOrgId === myOrgId) {
          return trade.buyerData;
        } else {
          return trade.sellerData;
        }
      } else {
        if (buyOrgId === myOrgId) {
          return trade.sellerData;
        } else {
          return trade.buyerData;
        }
      }
    };
    const getRfqType = (rfqId) => {
      let value = 'SPOT';
      if (isObject(rfqsById[rfqId])) {
        const rfq = rfqsById[rfqId];
        const extensions = JSON.parse(rfq.extensions);
        if (isObject(extensions)) {
          if (extensions.rfqType) {
            value = extensions.rfqType;
          }
        }
      }
      return value;
    };

    const getUnderlyingName = (trade) => {
      let name = 'underlying';
      if (trade) {
        if (trade.assets) {
          if (trade.assets[0]) {
            if (trade.assets[0].contract) {
              if (trade.assets[0].contract.orderContractUnderlying) {
                name = 'orderContractUnderlying';
              }
            }
          }
        }
      }
      return name;
    };

    const volumePerUnit = (unit, product, cartonNetWeight = null) => {
      let value = 1;
      if (unit === 'BOX') {
        if (product === 'SALMON') {
          value = 20;
        } else {
          value = 22.5;
        }
      }
      switch (cartonNetWeight) {
        case 'fifteen':
          value = 15;
          break;
        case 'twelveAndHalf':
        case 'tweleveAndHalf':
          value = 12.5;
          break;
        case 'twelve':
          value = 12;
          break;
        default:
          value = 1;
      }

      return value;
    };

    Object.values(tradeIds).forEach((id) => {
      const trade = trades[id];

      if (!isObject(trade)) return; // TODO: review why does not trade exist?

      const counterpartyIds = getCounterparty(
        trade.sellOrganisationId,
        trade.buyOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const counterpartyCompany = getFullOrg(counterpartyIds.orgId);
      const counterpartyUser = getFullUser(counterpartyIds.userId);
      const ownIds = getOwn(
        trade.sellOrganisationId,
        trade.buyOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const ownCompany = getFullOrg(ownIds.orgId);
      const ownUser = getFullUser(ownIds.userId);

      const ownComments = getComments(
        trade.buyOrganisationId,
        trade.buyOrderId,
        trade.sellOrderId,
        true,
      );
      const counterpartyComments = getComments(
        trade.buyOrganisationId,
        trade.buyOrderId,
        trade.sellOrderId,
        false,
      );
      const ownData = getData(trade.buyOrganisationId, true, trade);
      const counterpartyData = getData(trade.buyOrganisationId, false, trade);
      const rfqId = trade.rfqId;
      const rfqComments = isObject(rfqsById[rfqId]) ? rfqsById[rfqId].textMessage : '';
      const rfqType = getRfqType(rfqId);
      const underlyingName = getUnderlyingName(trade);
      trade.assets.forEach((data) => {
        const unit = data.contract.underlying.unit || 'KG';
        const cartonNetWeight = data.contract.underlying.cartonNetWeight || null;
        const product = data.contract.underlying.product || 'SALMON';
        const volPerUnit = volumePerUnit(unit, product, cartonNetWeight);
        const nominal = parseInt(data.price * data.volume * volPerUnit, 10);
        const cancellationInitiatedByOrgId = trade.cancellationInitiatedByOrgId;
        const cancellationRequestorShortName = cancellationInitiatedByOrgId
          ? cancellationInitiatedByOrgId === ownCompany.id
            ? ownCompany.shortName
            : counterpartyCompany.shortName
          : '';
        const formattedTrade = {
          id: data.id,
          status: trade.status,
          cancellationStatus: trade.cancellationStatus,
          cancellationInitiatedByOrgId: trade.cancellationInitiatedByOrgId,
          cancellationRequestorShortName,
          contract: data.contract,
          direction: getDirection(data.direction, trade.buyOrganisationId, user.id),
          volume: data.volume,
          price: data.price,
          counterpartyId: counterpartyCompany.id,
          counterpartyName: counterpartyCompany.name,
          counterpartyShortName: counterpartyCompany.shortName,
          counterpartyUser: counterpartyUser.name,
          counterpartyData,
          buyerData: data.buyerData,
          sellerData: data.sellerData,
          ownId: ownCompany.id,
          ownName: ownCompany.name,
          ownShortName: ownCompany.shortName,
          ownUser: ownUser.name,
          ownData,
          buyUser: trade.buyUserId,
          buyOrganisation: trade.buyOrganisationId,
          sellUser: trade.sellUserId,
          sellOrganisation: trade.sellOrganisationId,
          tradeGroupId: trade.id,
          rfqId: trade.rfqId,
          rfqType,
          createdTime: trade.createdTime,
          updatedTime: trade.updatedTime,
          nominal,
          counterpartyComments,
          ownComments,
          rfqComments,
        };

        formattedTrades.push(formattedTrade);
      });
    });

    return formattedTrades;
  },
);

export const getBlotterMyTrades = createSelector(
  getAll,
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,

  (trades, user, users, orgs) => {
    const myOrgId = user.organisationId;
    const formattedTrades = [];

    const getDirection = (direction, buyOrgId) => {
      if (buyOrgId === myOrgId) {
        return direction;
      }
      if (direction === 'SELL') {
        return 'BUY';
      }
      return 'SELL';
    };

    const getFullOrg = (id) => {
      const organisation = {};
      if (typeof orgs[id] !== 'undefined') {
        organisation.id = id;
        organisation.name = orgs[id].name;
        organisation.shortName = orgs[id].shortName;
      }
      return organisation;
    };

    const getFullUser = (id) => {
      const fulluser = {};
      if (typeof users[id] !== 'undefined') {
        fulluser.id = id;
        fulluser.name = `${users[id].firstName} ${users[id].lastName}`;
      }
      return fulluser;
    };

    const getCounterparty = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId === myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    const getOwn = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId !== myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    Object.values(trades).forEach((trade) => {
      const counterpartyIds = getCounterparty(
        trade.buyOrganisationId,
        trade.sellOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const counterpartyCompany = getFullOrg(counterpartyIds.orgId);
      const counterpartyUser = getFullUser(counterpartyIds.userId);
      const ownIds = getOwn(
        trade.buyOrganisationId,
        trade.sellOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const ownCompany = getFullOrg(counterpartyIds.orgId);
      const ownUser = getFullUser(counterpartyIds.userId);

      trade.assets.forEach((data) => {
        const nominal = data.contract.underlying
          ? data.contract.underlying.unit === 'BOX'
            ? parseInt(data.price * data.volume * 20, 10)
            : parseInt(data.price * data.volume, 10)
          : parseInt(data.price * data.volume, 10);
        const formattedTrade = {
          id: data.id,
          status: trade.status,
          contract: data.contract,
          direction: getDirection(data.direction, trade.buyOrganisationId, user.id),
          volume: data.volume,
          price: data.price,
          counterpartyName: counterpartyCompany.name,
          counterpartyShortName: counterpartyCompany.shortName,
          counterpartyUser: counterpartyUser.name,
          counterpartyOrganisationId: counterpartyIds.orgId,
          ownName: ownCompany.name,
          ownShortName: ownCompany.shortName,
          ownUser: ownUser.name,
          ownOrganisationId: ownIds.orgId,
          buyUser: trade.buyUserId,
          buyOrganisation: trade.buyOrganisationId,
          sellUser: trade.sellUserId,
          sellOrganisation: trade.sellOrganisationId,
          tradeGroupId: trade.id,
          rfqId: trade.rfqId,
          createTime: trade.createdTime,
          updatedTime: trade.updatedTime,
          nominal,
        };

        formattedTrades.push(formattedTrade);
      });
    });

    return formattedTrades.filter(
      ({ buyOrganisation, sellOrganisation }) =>
        buyOrganisation === myOrgId || sellOrganisation === myOrgId,
    );
  },
);

const getLastCreatedTimeProp = (state) => state[NAME].lastCreatedTime;
export const getLastCreatedTime = createSelector(
  getLastCreatedTimeProp,
  (lastCreatedTime) => lastCreatedTime,
);
