import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Modal,
  Button,
  Typography,
  Icon,
  InputLabel,
  Select,
  MenuItem,
  Card,
  FormControl,
} from '@material-ui/core';
import { checkPrice, checkVolume } from '../../../../commons/config/formatters';
import { directions, visibilities, status } from '../../../../commons/models/constants';

import ResponseFormRow from './ResponseFormRow';
import TotalsRow from '../TotalsRow';

import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton, ClearButton } from '../../../../commons/components/buttons';
import DirectionField from '../../../../commons/components/formFields/DirectionField';
import SelectField from '../../../../commons/components/formFields/SelectField';
import MultipleSelectFieldOrgs from '../../../../commons/components/formFields/multipleSelectFieldOrgs';
import DateTimeField from '../../../../commons/components/formFields/dateTimeField';
import DateField from '../../../../commons/components/formFields/dateField';
import TextFieldFilled from '../../../../commons/components/formFields/textFieldFilled';
import SwitchField from '../../../../commons/components/formFields/switchField';
import { parseExtensions } from '../../../../modules/contracts/utils/modelFunctions';

import {
  isOpenFormResponse,
  getOrders,
  getOrganisations,
  getRfqResponseForm,
  getActiveUser,
  getOrganisationsObject,
  getOrdersResponseForm,
  getDefaultOrdersResponseForm,
  getActiveUserOrganisation,
  getOrdersResponseFormEnumerations,
  getResponseFormFields,
  getOrderIdToCancelResponseForm,
} from '../../selectors';

import { updateForm } from '../../actions';
import { UPDATE_RESPONSE_FORM } from '../../constants';

import orders from '../../../orders';
import { ContactlessOutlined } from '@material-ui/icons';

const ERROR_RECIPIENT = 'At least one organisation must be selected.';
const FIELD_WIDTH = '150px';
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '98vw',
    height: '95vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  paperNotRfqOwner: {
    position: 'absolute',

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.inner,
  },

  section: {
    marginTop: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  sectionFlex: {
    marginTop: theme.spacing(0.5),
    //flex: 1,
    //display: "flex",
    //flexDirection: "column",
    //overflow: "auto"
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5),
    //backgroundColor: theme.palette.primary.row,
  },
  totals: {
    display: 'flex',
    flexDirection: 'column',
  },

  orders: {
    //backgroundColor: theme.palette.primary.row,
    //padding: theme.spacing(1),
    flex: 1,
    //overflowY: 'scroll',
  },

  banner: {
    //backgroundColor: theme.palette.primary.dark,
    //padding: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },

  title: { fontSize: theme.fontSize * 1.5 },
  comments: {
    padding: '8x !important',
    margin: '2.47px',
  },
});

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class ResponseForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandedDefault: false,
      expandedOrders: false,
      textMessage: '',
      timeInForce: null,
      statusChecked: true,
      showDetails: false,
    };
  }

  expandSection = (section) => {
    if (section === 'defaultOrders') {
      this.setState({ expandedDefault: !this.state.expandedDefault });
    }
    if (section === 'orders') {
      this.setState({ expandedOrders: !this.state.expandedOrders });
    }
  };

  getDirection = () => {
    const isRfqRequestor = this.props.activeRfq.organisationId === this.props.user.organisationId;
    const direction =
      isRfqRequestor === true
        ? this.props.activeRfq.direction === directions.BUY
          ? directions.BUY
          : directions.SELL
        : this.props.activeRfq.direction === directions.BUY
        ? directions.SELL
        : directions.BUY;
    return direction;
  };

  getOrganisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  addOrderRow = (actionType = 'addRow') => {
    const payload = {
      type: 'response',
      action: actionType,
    };
    this.props.updateResponseForm(payload);
  };

  deleteOrderRow = (key, actionType = 'deleteRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };
  copyOrderRow = (key, actionType = 'copyRow') => {
    const payload = {
      type: 'response',
      action: actionType,
      key,
    };
    this.props.updateResponseForm(payload);
  };

  handleTextMessage = (textMessage) => {
    this.setState({ textMessage });
  };
  handleTimeInForce = (name) => (event) => {
    if (name === 'validity') {
      const val = this.state.timeInForce === null ? moment().format('YYYY-MM-DDTHH:mm') : null;
      this.setState({ timeInForce: val });
    } else {
      this.setState({ timeInForce: event.target.value });
    }
  };

  handleChange = (name) => (event) => {
    const payload = {
      type: 'response',
      action: 'updateAll',
      field: name,
      value: event.target.value,
    };

    this.props.updateResponseForm(payload);
  };

  handleDateChange = (name) => (date) => {
    const payload = {
      type: 'response',
      action: 'updateAll',
      field: name,
      value: date,
    };

    this.props.updateResponseForm(payload);
  };

  handleChecked = () => {
    this.setState({ statusChecked: !this.state.statusChecked });
  };

  buildOrders = () => {
    const payload = {
      type: 'response',
      action: 'buildOrders',
    };
    this.props.updateResponseForm(payload);
  };

  updatePrices = () => {
    const payload = {
      type: 'response',
      action: 'updatePrices',
    };
    this.props.updateResponseForm(payload);
  };

  updateOrderRow = (actionType) => (payload) => {
    if (actionType === 'defaultOrder') {
      payload.action = 'updateDefaultOrderRow';
    }
    this.props.updateResponseForm(payload);
  };

  getErrors = () => {
    const orders = this.props.ordersRFQ || {};
    let error = false;
    orders.forEach((order) => {
      if (!checkPrice(order.price)) {
        error = true;
      }
      if (!checkVolume(order.volume)) {
        error = true;
      }
    });

    return error;
  };
  sendOrder = () => {
    const hasRowErrors = this.getErrors();
    const hasRecipientErrors = this.getErrorSelectedOrganisationsIds();
    let orderRows = this.props.ordersRFQ;
    const { orderIdToCancel } = this.props;
    const defaultOrders = this.props.defaultOrders;
    if (!hasRowErrors && !hasRecipientErrors.value) {
      orderRows.forEach((row) => {
        row.textMessage = this.state.textMessage;
      });
      const textMessage = this.state.textMessage || '';
      const createOrderPayload = {
        orderRows,
        textMessage,
        defaultOrders,
        timeInForce: this.state.timeInForce,
        orderIdToCancel: orderIdToCancel,
        status: this.state.statusChecked ? 'SUSPENDED' : 'ACTIVE',
      };

      if (orderIdToCancel) {
        const orderId = this.props.orderIdToCancel;
        const orderToAmend = {
          orderId,
          status: status.CANCELLED,
          //assets: order.assets,
        };
        const payload = { items: [orderToAmend] };
        this.props.updateOrders(payload);
      }
      console.log('rfq:forms:responseForm:send:payload', createOrderPayload);
      this.props.createOrder(createOrderPayload);
      this.setState({ textMessage: '' });
      this.closeForm();
    }
  };

  getOrgansisations = () => {
    const { activeRfq, organisationsObject, organisations, userOrganisation } = this.props;
    let orgs = [];

    if (activeRfq) {
      if (activeRfq.visibility === 'SELECTED_ORGANISATIONS') {
        activeRfq.selectedOrganisationsIds.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else if (activeRfq.visibility === 'TRADING_PARTNERS') {
        const tradingPartners = userOrganisation.tradingPartnersIds || [];
        tradingPartners.forEach((id) => {
          orgs.push(organisationsObject[id]);
        });
      } else {
        orgs = organisations;
      }
    }

    return orgs;
  };

  closeForm = () => {
    const payload = {
      type: 'response',
      action: 'closeForm',
    };

    this.props.updateResponseForm(payload);
  };

  getVisibilityOptions = (activeRfq) => {
    if (activeRfq === undefined || activeRfq === null) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.PUBLIC) {
      return visibilities;
    }
    if (activeRfq.visibility === visibilities.TRADING_PARTNERS) {
      return {
        TRADING_PARTNERS: 'TRADING_PARTNERS',
        SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS',
      };
    }
    return { SELECTED_ORGANISATIONS: 'SELECTED_ORGANISATIONS' };
  };
  getErrorSelectedOrganisationsIds = () => {
    const { activeRfq, user, ordersRFQ } = this.props;
    if (activeRfq.organisationId === user.organisationId) {
      if (ordersRFQ.length > 0) {
        if (ordersRFQ[0].visibility === visibilities.SELECTED_ORGANISATIONS) {
          if (ordersRFQ[0].selectedOrganisationsIds.length === 0) {
            return { message: ERROR_RECIPIENT, value: true };
          } else {
            return { message: '', value: false };
          }
        }
      }
    }
    return { message: '', value: false };
  };
  getFieldComponent = (field, order, orderRow, orderRowFields) => {
    const { activeRfq, enumerations } = this.props;

    switch (orderRowFields[field].component) {
      case 'text':
        return (
          <TextFieldFilled
            accessor={field}
            key={`${field}-${orderRow.key}`}
            displayName={orderRowFields[field].displayName}
            value={order[field] || ''}
            handleChange={this.handleChange}
            width={FIELD_WIDTH}
          ></TextFieldFilled>
        );
      case 'select':
        return (
          <SelectField
            accessor={field}
            key={`${field}-${orderRow.key}`}
            displayName={orderRowFields[field].displayName}
            value={order[field] || ''}
            values={enumerations[field]}
            handleChange={this.handleChange}
            width={FIELD_WIDTH}
          />
        );
      case 'date':
        return (
          <DateField
            accessor={field}
            displayName={orderRowFields[field].displayName}
            value={orderRow[field]}
            disablePast={orderRowFields[field].disablePast}
            handleDateChange={this.handleDateChange}
            /* minDate={
              activeRfq.contract[field] !== null
                ? moment(activeRfq.contract[field].from)
                : moment(new Date())
            }
            maxDate={
              activeRfq.contract[field].to !== null
                ? moment(activeRfq.contract[field].to)
                : moment(new Date()).add(365, 'days')
            } */
            width={FIELD_WIDTH}
            key={`${field}-${orderRow.key}`}
            clearable={orderRowFields[field].clearable}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { classes, activeRfq, open, user, formFields } = this.props;

    const isRfqRequestor = activeRfq ? activeRfq.organisationId === user.organisationId : false;
    const orders = this.props.ordersRFQ || [];
    const defaultOrders = this.props.defaultOrders || [];
    const order = orders[0] || {};
    const enumerations = this.props.enumerations || [];
    console.log('enumerations', enumerations);
    const direction = order.direction;
    const directions = [direction];
    const visibility = order.visibility;
    const visibilitiesOptions = this.getVisibilityOptions(activeRfq);
    const selectedOrganisations = order.selectedOrganisationsIds;
    const organisations = this.getOrgansisations();
    const errorSelectedOrganisationsIds = this.getErrorSelectedOrganisationsIds();
    const extensions = parseExtensions(activeRfq);
    const rfqType = extensions.rfqType ? extensions.rfqType : 'STANDARD';
    const { timeInForce } = this.state;

    const orderRowFields = formFields.allFields || null;
    const orderRowFieldKeys = orderRowFields ? Object.keys(orderRowFields) : [];
    const orderRow = orders[0] || null;
    const formFieldsTop = orderRowFieldKeys.filter((f) => orderRowFields[f].formTopSection);
    const formFieldsLeft = orderRowFieldKeys.filter((f) => orderRowFields[f].formLeftSection);
    const formFieldsRight = orderRowFieldKeys.filter((f) => orderRowFields[f].formRightSection);
    const formFieldsBottom = orderRowFieldKeys.filter((f) => orderRowFields[f].formBottomSection);

    return (
      <Modal open={open} onClose={this.closeForm}>
        <div
          style={getModalStyle()}
          className={
            (isRfqRequestor || direction === 'BUY') && this.state.showDetails
              ? classes.paper
              : classes.paperNotRfqOwner
          }
        >
          <Typography className={classes.title}>RFQ RESPONSE</Typography>
          <div className={classes.container}>
            <div className={classes.section}>
              <div className={classes.subsection}>
                <DirectionField
                  accessor="direction"
                  displayName="Direction"
                  value={direction}
                  values={directions}
                  handleChange={this.handleChange}
                  fullWidth
                />
                {/*   {isRfqRequestor && (
                  <SelectField
                    accessor={'visibility'}
                    displayName={'Visibility'}
                    value={visibility}
                    values={Object.keys(visibilitiesOptions)}
                    handleChange={this.handleChange}
                    fullWidth
                  />
                )} */}
                {order.visibility === 'SELECTED_ORGANISATIONS' && isRfqRequestor && (
                  <MultipleSelectFieldOrgs
                    key="selectedOrganisationsIds"
                    accessor="selectedOrganisationsIds"
                    displayName="Client:"
                    value={selectedOrganisations}
                    values={organisations}
                    handleChange={this.handleChange}
                    errorRecipientsMessage={errorSelectedOrganisationsIds.message}
                    errorValue={errorSelectedOrganisationsIds.value}
                    fullWidth
                  />
                )}
                <SelectField
                  accessor="validity"
                  displayName="Validity"
                  value={timeInForce !== null ? 'TIME IN FORCE' : 'GTC'}
                  values={['GTC', 'TIME IN FORCE']}
                  handleChange={this.handleTimeInForce}
                  fullWidth
                />
                {timeInForce !== null && (
                  <DateTimeField
                    accessor="timeInForce"
                    displayName="Time In Force"
                    value={timeInForce}
                    handleChange={this.handleTimeInForce}
                    fullWidth
                  />
                )}
              </div>
            </div>

            {/*   <div className={classes.section}>
              <div className={classes.subsection}>
                <InputBase
                  value={this.state.textMessage}
                  type="text"
                  label="Comments"
                  placeholder="Comments"
                  multiline
                  //fullWidth
                  style={{
                    flex: 1,
                    border: '1px solid rgba(255,255,255,.09)',
                    borderRadius: '4px',
                    padding: '8px',
                    margin: '2.47px',
                  }}
                  inputProps={{
                    className: classes.comments,
                  }}
                  onChange={(e) => this.handleTextMessage(e.target.value)}
                />
               
              </div>
            </div> */}

            <div className={classes.sectionFlex}>
              {/*   <div className={classes.bannerContainer}>
                <Typography
                  className={classes.banner}
                >{`CARTONS (${orders.length} items)`}</Typography>
                <div>
                  <Button
                    style={{ fontSize: 'inherit' }}
                    aria-label="Delete"
                    onClick={() => this.addOrderRow()}
                  >
                    <Icon className={classes.extendedIcon}>add</Icon>
                    <Typography style={{ textAlign: 'right' }}>Add Template Row</Typography>
                  </Button>
                  <Button
                    style={{ fontSize: 'inherit' }}
                    aria-label="Expand"
                    onClick={() => this.expandSection('orders')}
                  >
                    <Icon className={classes.extendedIcon}>fullscreen</Icon>
                  </Button>
                </div>
              </div> */}
              {/* <div className={classes.orders}>
                {orders.map((row, index) => {
                  return (
                    <ResponseFormRow
                      key={row.key}
                      orderRow={row}
                      activeRfq={activeRfq}
                      copyOrderRow={() => this.copyOrderRow(row.key)}
                      deleteOrderRow={() => this.deleteOrderRow(row.key)}
                      updateOrderRow={this.updateOrderRow('order')}
                      index={index}
                      enumerations={enumerations}
                      formFields={formFields.defaultRowFields}
                    />
                  );
                })}
              </div> */}
              {(isRfqRequestor || direction === 'BUY') && this.state.showDetails && (
                <React.Fragment>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>{`COMPANY`}</Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {orderRowFields &&
                        formFieldsTop.map((field) => {
                          if (orderRowFields[field] && orderRow) {
                            return this.getFieldComponent(field, order, orderRow, orderRowFields);
                          }
                        })}
                    </Card>
                  </div>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>{`PRODUCT`}</Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsLeft.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return this.getFieldComponent(field, order, orderRow, orderRowFields);
                            }
                          })}
                      </Card>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography className={classes.banner}>{`FREIGHT`}</Typography>

                      <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                        {orderRowFields &&
                          formFieldsRight.map((field) => {
                            if (orderRowFields[field] && orderRow) {
                              return this.getFieldComponent(field, order, orderRow, orderRowFields);
                            }
                          })}
                      </Card>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography className={classes.banner}>{`OFFER`}</Typography>

                    <Card style={{ margin: '0px 4px 4px', padding: '8px' }}>
                      {orderRowFields &&
                        formFieldsBottom.map((field) => {
                          if (orderRowFields[field] && orderRow) {
                            return this.getFieldComponent(field, order, orderRow, orderRowFields);
                          }
                        })}
                    </Card>
                  </div>
                </React.Fragment>
              )}
              <div style={{ flex: 1 }}>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  {' '}
                  <SwitchField
                    offLabel="LIVE"
                    onLabel="INDICATIVE"
                    handleChecked={this.handleChecked}
                    checked={this.state.statusChecked}
                  />
                  {(isRfqRequestor || direction === 'BUY') && (
                    <ClearButton
                      icon="visibility"
                      title="Details"
                      onClick={() => this.setState({ showDetails: !this.state.showDetails })}
                    />
                  )}
                  <ClearButton icon="add" title="Add Item" onClick={() => this.addOrderRow()} />
                </div>

                <div className={classes.orders}>
                  {orders.map((row, index) => {
                    return (
                      <ResponseFormRow
                        key={row.key}
                        orderRow={row}
                        activeRfq={activeRfq}
                        copyOrderRow={() => this.copyOrderRow(row.key)}
                        deleteOrderRow={() => this.deleteOrderRow(row.key)}
                        updateOrderRow={this.updateOrderRow('order')}
                        index={index}
                        enumerations={enumerations}
                        formFields={formFields.defaultRowFields}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.totals}>
            <TotalsRow rows={orders} direction={directions[0]} />
          </div>
          <ToolbarBottom>
            <>
              <CancelButton onClick={this.closeForm} />
              <ConfirmButton onClick={this.sendOrder} />
            </>
          </ToolbarBottom>
        </div>
      </Modal>
    );
  }
}

ResponseForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    orders: getOrders(state),
    user: getActiveUser(state),
    userOrganisation: getActiveUserOrganisation(state),
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    open: isOpenFormResponse(state),
    activeRfq: getRfqResponseForm(state),
    ordersRFQ: getOrdersResponseForm(state),
    enumerations: getOrdersResponseFormEnumerations(state),
    formFields: getResponseFormFields(state),
    defaultOrders: getDefaultOrdersResponseForm(state),
    orderIdToCancel: getOrderIdToCancelResponseForm(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    createOrder: (payload) => {
      dispatch(orders.actions.createOrder(orders.constants.CREATE_ORDER_FROM_RFQ, payload));
    },
    updateOrders: (payload) => {
      dispatch(orders.actions.updateOrders(orders.constants.UPDATE_ORDERS, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponseForm));
