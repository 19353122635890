import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  Modal,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Card,
} from '@material-ui/core';

import SelectField from '../../../../commons/components/formFields/SelectField';
import TextInputField from '../../../../commons/components/formFields/TextInputField';
import MultipleSelectField from '../../../../commons/components/formFields/multipleSelectField';
import ToolbarBottom from '../../../../commons/components/toolbarBottom';

import { CancelButton, ConfirmButton } from '../../../../commons/components/buttons';

import {} from '../../selectors';
import { createAdminItem, updateAdminItem } from '../../actions';
import { CREATE_ADMIN_ITEM, UPDATE_ADMIN_ITEM } from '../../constants';
import logos from '../../../logos';
import { CloseSharp } from '@material-ui/icons';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '80vw',
    maxHeight: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.inner,
  },
  containerItems: {
    backgroundColor: theme.palette.primary.row,
    padding: '10px',
  },
  card: {
    margin: '0px 4px 4px',
    padding: '4px',
  },
  section: {
    marginTop: theme.spacing(0.5),
  },
  banner: {
    backgroundColor: theme.palette.primary.dark,
    padding: '5px',
    color: theme.palette.text.disabled,
  },
  title: {},
  banner: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    fontSize: theme.typography.fontSize,
  },
  subheader: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.5),
  },
});
const initialState = {
  name: '',
  shortName: '',
  type: '',
  tradingPartnersIds: [],
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postcode: '',
  locality: '',
  country: '',
  brands: '',
  processingPoints: '',
  species: [],
  description: '',
  nameError: { value: false, message: '' },
  shortNameError: { value: false, message: '' },
};

// TODO: Extract all functionality to saga ans store. REFACTOR!!!! CRITICAL!!!!!
class UpdateOrganisation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    if (this.props.organisation !== null && this.props.organisation !== undefined) {
      const { organisation } = this.props;

      const {
        name,
        shortName,
        tradingPartnersIds,
        address,
        details,
        description,
        type,
      } = organisation;
      const { address1, address2, address3, city, postcode, locality, country } = address || {};
      const { brands, processingPoints, species } = details || {};
      this.setState({
        name,
        shortName,
        tradingPartnersIds: tradingPartnersIds || [],
        species: species || [],
        description,
        type,
        address1,
        address2,
        address3,
        brands: brands ? brands[0] : '',
        processingPoints: processingPoints ? processingPoints[0] : '',
        city,
        postcode,
        locality,
        country,
      });
    }
  }

  componentDidUpdate(prevProps) {}

  handleChange = (name) => (event) => {
    const value = event.target.value;
    let error = { value: false, message: '' };
    if (value === '' || value === null || value === undefined) {
      error = { value: true, message: 'Value is required' };
    }
    this.setState({ [name]: event.target.value, [`${name}Error`]: error });
  };

  handleChecked = (id) => () => {
    const checked = this.state.tradingPartnersIds;
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ tradingPartnersIds: newChecked });
  };
  getErrors = () => {
    let hasErrors = false;
    let { nameError, shortNameError } = this.state;
    if (this.state.name === '' || this.state.name === null || this.state.name === undefined) {
      nameError = { value: true, message: 'Value is required' };
      hasErrors = true;
    } else {
      nameError = { value: false, message: '' };
    }
    if (
      this.state.shortName === '' ||
      this.state.shortName === null ||
      this.state.shortName === undefined
    ) {
      shortNameError = { value: true, message: 'Value is required' };
      hasErrors = true;
    } else {
      shortNameError = { value: false, message: '' };
    }
    return { hasErrors, nameError, shortNameError };
  };
  send = () => {
    const errors = this.getErrors();
    if (!errors.hasErrors) {
      const name = this.state.name;
      const shortName = this.state.shortName;
      const tradingPartnersIds = this.state.tradingPartnersIds;
      const type = this.state.type;
      const description = this.state.description;
      const address = {
        address1: this.state.address1,
        address2: this.state.address2,
        address3: this.state.address3,
        city: this.state.city,
        postcode: this.state.postcode,
        locality: this.state.locality,
        country: this.state.country,
      };
      const details = {
        species: this.state.species,
        brands: [this.state.brands],
        processingPoints: this.state.processingPoints,
        products: [],
        countries: [],
        regions: [],
      };
      const payload = {
        type: 'organisations',
        body: {
          name,
          shortName,
          type,
          address,
          tradingPartnersIds,
          details,
          description,
        },
        id: this.props.organisation.id,
      };
      console.log('UpdateOrganisation:send:payload', payload);
      this.props.update(payload);
      this.props.closeForm();
      this.setState({ ...initialState });
    }

    this.setState({ nameError: errors.nameError, shortNameError: errors.shortNameError });
  };

  render() {
    const { classes, open, organisations } = this.props;
    const { tradingPartnersIds } = this.state;
    return (
      <div>
        <Modal open={open} onClose={this.closeForm}>
          <div style={getModalStyle()} className={`${classes.paper} undraggable`}>
            <Typography className={classes.title}>EDIT ORGANISATION</Typography>
            <div className={classes.container}>
              <div className={classes.section}>
                <Typography className={classes.banner}>ORGANISATION</Typography>
                <Card className={classes.card}>
                  <TextInputField
                    accessor="name"
                    displayName="Organisation Full Name"
                    value={this.state.name}
                    handleChange={this.handleChange}
                    error={this.state.nameError}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="shortName"
                    displayName="Organisation Short Name"
                    value={this.state.shortName}
                    handleChange={this.handleChange}
                    error={this.state.shortNameError}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <SelectField
                    accessor="type"
                    displayName="Organisation Type"
                    value={this.state.type || ''}
                    values={['BUYER', 'SELLER', 'AGENT']}
                    handleChange={(name) => (e) => {
                      this.setState({
                        [name]: e.target.value,
                        typeError: { value: false, message: '' },
                      });
                    }}
                    error={this.state.typeError}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                </Card>
              </div>
              <div className={classes.section}>
                <Typography className={classes.banner}>ADDRESS</Typography>
                <Card className={classes.card}>
                  <TextInputField
                    accessor="address1"
                    displayName="Address 1"
                    value={this.state.address1}
                    handleChange={this.handleChange}
                    error={this.state.address1Error}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="address2"
                    displayName="Address 2"
                    value={this.state.address2}
                    handleChange={this.handleChange}
                    error={this.state.address2Error}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="address3"
                    displayName="Address 3"
                    value={this.state.address3}
                    handleChange={this.handleChange}
                    error={this.state.address3Error}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="city"
                    displayName="city"
                    value={this.state.city}
                    handleChange={this.handleChange}
                    error={this.state.cityError}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="postcode"
                    displayName="Postcode"
                    value={this.state.postcode}
                    handleChange={this.handleChange}
                    error={this.state.postCodeError}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="locality"
                    displayName="Locality"
                    value={this.state.locality}
                    handleChange={this.handleChange}
                    error={this.state.locality}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="country"
                    displayName="Country"
                    value={this.state.country}
                    handleChange={this.handleChange}
                    error={this.state.country}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                </Card>
              </div>
              <div className={classes.section}>
                <Typography className={classes.banner}>PRODUCT</Typography>

                <Card className={classes.card}>
                  <TextInputField
                    accessor="brands"
                    displayName="Brand"
                    value={this.state.brands}
                    handleChange={this.handleChange}
                    error={this.state.brands}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <TextInputField
                    accessor="processingPoints"
                    displayName="Processing Point"
                    value={this.state.processingPoints}
                    handleChange={this.handleChange}
                    error={this.state.processingPoints}
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                  <MultipleSelectField
                    accessor="species"
                    key="user_update_species"
                    displayName="Species"
                    value={this.state.species || []}
                    values={this.props.species}
                    handleChange={this.handleChange}
                    fullWidth
                  />
                </Card>
              </div>

              <div className={classes.section}>
                <Typography className={classes.banner}>DESCRIPTION</Typography>
                <Card className={classes.card}>
                  <TextInputField
                    accessor="description"
                    displayName="Description"
                    value={this.state.description}
                    handleChange={this.handleChange}
                    error={this.state.description}
                    fullWidth
                    multiline
                    maxRows={5}
                    style={{ marginRight: 0, marginLeft: 0 }}
                  />
                </Card>
              </div>
              <div className={classes.section}>
                <Typography className={classes.banner}>LOGO</Typography>
                <Card className={classes.card}>
                  <logos.components.logoUpload
                    organisationId={this.props.organisation.id}
                    organisationShortName={this.state.shortName}
                  ></logos.components.logoUpload>
                </Card>
              </div>
              <div className={classes.section}>
                <Typography className={classes.banner}>TRADING PARTNERS</Typography>
                <Card className={classes.card}>
                  {organisations.map((org) => {
                    const id = org.id;
                    const labelId = `checkbox-list-label-${id}`;
                    const labelId2 = `checkbox-list-label2-${id}`;
                    return (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleChecked(id)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={tradingPartnersIds.indexOf(org.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={org.name} />
                        <ListItemText
                          id={labelId2}
                          primary={org.shortName}
                          style={{ textAlign: 'right' }}
                        />
                      </ListItem>
                    );
                  })}
                </Card>
              </div>
            </div>
            <ToolbarBottom>
              <>
                <CancelButton onClick={this.props.closeForm} />
                <ConfirmButton onClick={this.send} title="Update" />
              </>
            </ToolbarBottom>
          </div>
        </Modal>
      </div>
    );
  }
}

UpdateOrganisation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    update: (payload) => {
      dispatch(updateAdminItem(UPDATE_ADMIN_ITEM, payload));
    },
    create: (payload) => {
      dispatch(createAdminItem(CREATE_ADMIN_ITEM, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateOrganisation));
