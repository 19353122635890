import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, InputBase } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import red from '@material-ui/core/colors/red';

const styles = (theme) => ({
  chatLayover: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '72px',
    backgroundColor: red[500],
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightIcon: {
    marginRight: theme.spacing(),
  },
  inputBase: {
    flex: 1,
    margin: '10px 10px',
    padding: '0 16px',
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: 'white',
    fontSize: '16px',
    borderWidth: 0,
    // height: '80%',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  sendIcon: {
    color: theme.palette.text.disabled,
  },
});

const InputArea = ({
  classes,
  chatAllowed,
  allowChat,
  sendMessage,
  message,
  setMessage,
  keyPress,
}) => {
  return (
    <div>
      {chatAllowed === false && (
        <div className={classes.chatLayover}>
          <Button onClick={allowChat}>
            <Icon className={classes.rightIcon}>warning</Icon>Enable Public Chat Messaging
          </Button>
        </div>
      )}

      {chatAllowed === true && (
        <div
          style={{
            flexShrink: 1,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'rgba(0,0,0,.1)',
            minHeight: '72px',
          }}
        >
          <InputBase
            value={message}
            type="text"
            label="Send a Message"
            placeholder="Send a Message"
            multiline
            className={classes.inputBase}
            // onClick={() => this.saveChatViews(this.state.selectedRoom.roomId)}
            onKeyPress={keyPress}
            onChange={(e) => {
              // if (e.target.value.includes('\n')) {
              //   sendMessage(message);
              // } else {
              setMessage(e.target.value);
              // }
            }}
          />
          <Button onClick={() => sendMessage(message)}>
            <SendIcon className={classes.sendIcon} />
          </Button>
        </div>
      )}
    </div>
  );
};

InputArea.propTypes = {
  classes: PropTypes.object.isRequired,
  chatAllowed: PropTypes.bool,
  allowChat: PropTypes.func,
  sendMessage: PropTypes.func,
  setMessage: PropTypes.func,
  message: PropTypes.string,
};

export default withStyles(styles)(InputArea);
