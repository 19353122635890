import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getTheme } from '../../../commons/selectors';
import { createAction } from '../../../commons/actions';
import * as actionTypes from '../actionTypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Select, MenuItem, AppBar } from '@material-ui/core';
import * as selectors from '../selectors';
import { ClearButton } from '../../../commons/components/buttons';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.innerAlt,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.dividerAlt,
  },
  ratesContainer: {
    overflowY: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  responsiveContainer: {
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '32px',
    padding: '0px 8px',
  },
  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: '1px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
});

class Statistics extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showAll: true,
      sortOrder: [],
    };
  }

  handleSegmentValueChange = (e, name) => {
    const value = e.target.value;
    const payload = {
      [`${name}Value`]: value,
    };
    this.props.updateStatistics(payload);
  };
  refresh = () => {
    const payload = {
      speciesValue: this.props.values.species,
    };
    this.props.updateStatistics(payload);
  };

  getString = (word) => {
    const words = word
      .match(/([A-Z]?[^A-Z]*)/g)
      .join(' ')
      .toUpperCase();

    return words;
  };

  render() {
    const { classes, segmentValues, segmentValue, theme } = this.props;
    const value = this.getString('spinyLobster');
    const data = this.props.stats || [];

    return (
      <div className={` ${classes.container} undraggable`}>
        <div className={classes.header}>
          <Select
            value={this.props.values.species}
            disableUnderline
            name="defaultSegment"
            className={classes.headerTitle}
            onChange={(e) => this.handleSegmentValueChange(e, 'species')}
          >
            {this.props.valueEnums.species.map((seg) => {
              return (
                <MenuItem key={seg} value={seg}>
                  {this.getString(seg)}
                </MenuItem>
              );
            })}
          </Select>
          <ClearButton title="Refresh" icon="refresh" onClick={this.refresh}></ClearButton>
        </div>

        <div className={classes.responsiveContainer}>
          <ResponsiveContainer width="99%" height="97%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="1 1" stroke={theme.palette.dividerAlt} />
              <XAxis
                type="number"
                stroke={theme.palette.text.primaryLight}
                axisLine="false"
                unit=" CTNS"
              />
              <YAxis
                type="category"
                dataKey="name"
                stroke={theme.palette.text.primary}
                width={190}
              />
              <Tooltip
                wrapperStyle={{
                  width: 200,
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                }}
                contentStyle={{
                  backgroundColor: theme.palette.background.row,
                  color: theme.palette.text.primary,
                }}
                cursor={{ fill: theme.palette.background.row }}
              />
              <Legend
                verticalAlign="top"
                height="24px"
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                wrapperStyle={{ top: -5 }}
              />
              <Bar dataKey="Offers" fill={theme.palette.lightBlue} />
              <Bar dataKey="Indicative" fill={theme.palette.amber} />
              <Bar dataKey="Trades" fill={theme.palette.teal} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    valueEnums: selectors.getValueEnums(state),
    values: selectors.getValues(state),
    theme: getTheme(state),
    orders: selectors.getOrders(state),
    trades: selectors.getTrades(state),
    stats: selectors.getStats(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStatistics: (payload) => {
      dispatch(createAction(actionTypes.UPDATE_STATISTICS, payload, { receivedAt: new Date() }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Statistics));
