import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { withStyles } from '@material-ui/core/styles';
import Organisations from './Organisations';
import AddOrganisation from './forms/AddOrganisation';
import AddUser from './forms/AddUser';
import UpdateOrganisation from './forms/UpdateOrganisation';
import UpdateUser from './forms/UpdateUser';
import Users from './Users';
import { AppBar, Tabs, Tab, Button, Icon } from '@material-ui/core';
import {
  getOrganisations,
  getUsers,
  getOrganisationsObject,
  getUsersObject,
  getMyOrganisation,
} from '../selectors';
import { INIT_ADMIN, DESTROY_ADMIN } from '../constants';
import { GET_ORGANISATION } from '../../organisations/constants';
import { createAction } from '../../../commons/actions';
import ToolbarBottom from '../../../commons/components/toolbarBottom';

import { ResetButton } from '../../../commons/components/buttons';
import { getEnumerations } from '../../contracts/utils/modelFunctions';
import auth from '../../auth';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.innerAlt,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.dividerAlt,
  },
});

class Admin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      addOrganisationOpen: false,
      addUserOpen: false,
      updateOrganisationOpen: false,
      updateUserOpen: false,
      editableUser: null,
      editableOrganisation: null,
      species: getEnumerations().species,
    };
  }

  componentDidMount() {
    const { id, widget } = this.props;
    const payload = {
      id,
      widget,
    };

    this.props.initAdmin(payload);
  }

  componentWillUnmount() {
    /*  const payload = {
      id: this.props.id,
    }; */
    //this.props.destroyAdmin(payload);
  }
  handleTabChange = (e, value) => {
    this.setState({ tabValue: value });
  };
  editOrganisation = (org) => {
    this.setState({
      editableOrganisation: org,
      updateOrganisationOpen: true,
    });
    this.props.getOrganisation({ organisationId: org.id });
  };
  editUser = (user) => {
    this.setState({
      editableUser: user,
      updateUserOpen: true,
    });
  };

  render() {
    const { classes, organisations, users, organisationsObject, myOrganisation } = this.props;
    const { tabValue } = this.state;

    return (
      <div className={`${classes.container} undraggable`}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={this.handleTabChange}>
            <Tab style={{}} label={'ORGANISATIONS'} />
            <Tab style={{}} label={'USERS'} />
          </Tabs>
        </AppBar>
        <div
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            flex: 1,
            padding: '16px 0px 16px 0px',
          }}
        >
          {this.state.tabValue === 0 ? (
            <Organisations
              organisations={organisations}
              organisationsObject={organisationsObject}
              editOrganisation={this.editOrganisation}
              isSuperAdmin={this.props.isSuperAdmin}
              isOrganisationAdmin={this.props.isOrganisationAdmin}
              myOrganisation={myOrganisation}
              users={users}
              species={this.state.species}
            />
          ) : (
            <Users
              users={users}
              organisations={organisations}
              organisationsObject={organisationsObject}
              editUser={this.editUser}
              isSuperAdmin={this.props.isSuperAdmin}
              isOrganisationAdmin={this.props.isOrganisationAdmin}
              myOrganisation={myOrganisation}
              species={this.state.species}
            />
          )}
        </div>

        {(this.props.isSuperAdmin || this.props.isOrganisationAdmin) && (
          <ToolbarBottom backgroundColorAlt>
            {this.props.isSuperAdmin && (
              <ResetButton
                title="Organisation"
                icon="add"
                onClick={() => {
                  this.setState({ addOrganisationOpen: true });
                }}
              />
            )}
            {this.props.isOrganisationAdmin && <div></div>}

            <ResetButton
              title="User"
              icon="add"
              onClick={() => {
                this.setState({ addUserOpen: true });
              }}
            />
          </ToolbarBottom>
        )}

        {this.state.addOrganisationOpen && this.props.isSuperAdmin && (
          <AddOrganisation
            open={this.state.addOrganisationOpen}
            closeForm={() => this.setState({ addOrganisationOpen: false })}
            organisations={organisations}
            species={this.state.species}
            isSuperAdmin={this.props.isSuperAdmin}
          ></AddOrganisation>
        )}

        {this.state.addUserOpen && (this.props.isSuperAdmin || this.props.isOrganisationAdmin) && (
          <AddUser
            open={this.state.addUserOpen}
            closeForm={() => this.setState({ addUserOpen: false })}
            organisations={organisationsObject}
            species={this.state.species}
            isSuperAdmin={this.props.isSuperAdmin}
          ></AddUser>
        )}
        {this.state.updateOrganisationOpen &&
          (this.props.isSuperAdmin || this.props.isOrganisationAdmin) && (
            <UpdateOrganisation
              open={this.state.updateOrganisationOpen}
              closeForm={() => this.setState({ updateOrganisationOpen: false })}
              organisationsObject={organisationsObject}
              organisations={organisations}
              organisation={this.state.editableOrganisation}
              species={this.state.species}
            />
          )}

        {this.state.updateUserOpen &&
          (this.props.isSuperAdmin || this.props.isOrganisationAdmin) && (
            <UpdateUser
              open={this.state.updateUserOpen}
              closeForm={() => this.setState({ updateUserOpen: false })}
              organisationsObject={organisationsObject}
              user={this.state.editableUser}
              species={this.state.species}
              isSuperAdmin={this.props.isSuperAdmin}
            />
          )}
      </div>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { id, widgetId, widget } = props;
  const cId = id || widgetId || widget.id;

  return {
    id: cId,
    organisations: getOrganisations(state),
    organisationsObject: getOrganisationsObject(state),
    myOrganisation: getMyOrganisation(state),
    users: getUsers(state),
    usersObject: getUsersObject(state),
    isSuperAdmin: auth.selectors.isSuperAdmin(state),
    isOrganisationAdmin: auth.selectors.isOrganisationAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initAdmin: (payload) => {
      dispatch(createAction(INIT_ADMIN, payload));
    },
    destroyAdmin: (payload) => {
      dispatch(createAction(DESTROY_ADMIN, payload));
    },
    getOrganisation: (payload) => {
      dispatch(createAction(GET_ORGANISATION, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Admin));
