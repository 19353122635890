import * as actionTypes from '../actionTypes';

//import { insertItems, updateItems } from '../../../commons/utils/functions';
import contract from '../../contracts/models/orderUnderlying';
import auth from '../../auth';

const initialState = {
  byId: [],
  allIds: [],
  items: {},
  speciesValues: Object.keys(contract.fields.species.enum),
  productValues: Object.keys(contract.fields.product.enum),
  speciesValue: 'southernRock',
  productValue: 'spinyLobster',
  orders: [],
  trades: [],
  stats: [],
  status: null,
  error: null,
  receivedAt: null,
  lastCreatedTime: null,
  lastUpdatedTime: null,
};

const reducer = (state = initialState, action) => {
  //let nextState = null;

  switch (action.type) {
    case actionTypes.INIT_STATISTICS_SUCCESS:
      let nextState = { ...initialState };
      //newState.segmentValues = Object.keys(contract.fields.product.enum);
      nextState.segmentValue = action.payload.segmentValue;
      return nextState;
    case actionTypes.UPDATE_STATISTICS_SUCCESS:
      nextState = { ...state, ...action.payload };

      return nextState;
    case auth.constants.SIGN_OUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default reducer;
