import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SelectField from '../../../commons/components/formFields/SelectField';
import UserProfile from './UserProfile';
import { Icon, TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultipleSelectFieldFilled from '../../../commons/components/formFields/multipleSelectFieldFilled';

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listItem: {
    flex: 1,
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
  },
  icon: {
    padding: theme.spacing(),
  },
});

class Users extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }
  getUsers = () => {
    if (this.state.value === null) {
      return this.props.users;
    } else {
      const organisation = this.state.value;

      const users = this.props.users.filter((user) => user.organisationId === organisation.id);
      return users;
    }
  };

  render() {
    const {
      organisationsObject,
      isSuperAdmin,
      isOrganisationAdmin,
      myOrganisation,
      classes,
      organisations,
    } = this.props;
    const users = this.getUsers();

    const CustomPaper = (props) => {
      return <Paper elevation={8} {...props} />;
    };
    return (
      <React.Fragment>
        <div className={classes.filterRow}>
          <div className={classes.icon}>
            <Icon>filter_list</Icon>
          </div>
          <Autocomplete
            id="combo-box-demo"
            options={organisations}
            getOptionLabel={(option) => option.name}
            style={{ width: 256 }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField {...params} label="Search Organisation" variant="filled" size="small" />
            )}
            onChange={(e, value) => this.setState({ value: value })}
            onInputChange={(e, value) => this.setState({ inputValue: value })}
          />

          <MultipleSelectFieldFilled
            accessor="species"
            displayName="Species"
            addUnderline
            value={this.state.species}
            values={this.props.species || []}
            handleChange={(name) => (e) => {
              this.setState({ [name]: e.target.value });
            }}
            width={200}
          />
        </div>

        {users.map((user) => {
          return (
            <UserProfile
              user={user}
              organisationsObject={organisationsObject}
              key={user.id}
              editUser={this.props.editUser}
              isSuperAdmin={isSuperAdmin}
              isOrganisationAdmin={isOrganisationAdmin}
              myOrganisation={myOrganisation}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
