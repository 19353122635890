import * as actionTypes from '../actionTypes';
import { createAction } from '../../../commons/actions';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { isObject } from '../../../commons/utils/functions';
import auth from '../../auth';
import api from '../api';
import * as selectors from '../selectors';
import marketSegments from '../../contracts/models/marketSegments';
function* init(action) {
  try {
    const {
      payload: { id, widget },
    } = action;

    const payload = {
      id,
    };

    const meta = { receivedAt: new Date() };

    if (isObject(widget)) {
      payload.widget = widget;
    }

    yield put(createAction(actionTypes.INIT_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.INIT_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}
function* update(action) {
  try {
    const { payload } = action;
    const token = yield call(auth.selectors.getToken);
    const data = {
      token,
      /*  params:{
        groupBy: "species",
        groupBy:"product",
        groupBy: "weightClass"
      } */
      params: {},
    };
    const values = yield select(selectors.getValues);
    const species = payload.speciesValue || values.species;

    const orders = yield call(api.get, 'ordersVolume', data);

    const trades = yield call(api.get, 'tradesVolume', data);

    const stats = getStats(orders, trades, species);
    payload.orders = orders;
    payload.trades = trades;
    payload.stats = stats;

    const meta = { receivedAt: new Date() };

    yield put(createAction(actionTypes.UPDATE_STATISTICS_SUCCESS, payload, meta));
  } catch (error) {
    yield put(
      createAction(actionTypes.UPDATE_STATISTICS_ERROR, { error }, { receivedAt: new Date() }),
    );
  }
}

export function* watchInitStatistics() {
  yield takeEvery(actionTypes.INIT_STATISTICS, init);
}

export function* watchUpdateStatistics() {
  yield takeEvery(actionTypes.UPDATE_STATISTICS, update);
}

function getStats(orders, trades, species) {
  const marketSegment = Object.keys(marketSegments).find(
    (item) => marketSegments[item].species === species,
  );
  const weightClasses = Object.keys(marketSegments[marketSegment].enums.weightClass) || [];
  const stats = [];
  weightClasses.forEach((wgt) => {
    const filteredOrders = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'ACTIVE',
      )
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredIndicative = [...orders]
      .filter(
        (o) =>
          o.direction === 'SELL' &&
          o.species === species &&
          o.weightClass === wgt &&
          o.status === 'SUSPENDED',
      )
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const filteredTrades = trades
      .filter((t) => t.species === species && t.weightClass === wgt)
      .reduce(function (sum, value) {
        return sum + value.volume;
      }, 0);
    const obj = {
      name: wgt,
      Offers: filteredOrders ? filteredOrders : 0,
      Indicative: filteredIndicative ? filteredIndicative : 0,
      Trades: filteredTrades ? filteredTrades : 0,
    };
    stats.push(obj);
  });

  return stats;
}
