import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

import amber from '@material-ui/core/colors/amber';
import SelectField from '../../../commons/components/formFields/SelectField';
import CheckboxField from '../../../commons/components/formFields/checkboxField';
import CollapseContainer from '../../../commons/components/collapseContainer';
import DateField from '../../../commons/components/formFields/dateField';
import { CancelButton, ConfirmButton } from '../../../commons/components/buttons';
import Tab from './Tab';
import EndAllRfqs from './EndAllRfqs';

import {
  getAll,
  getViews,
  getDismissed,
  getUserId,
  getSendersRFQs,
  getOrganisationsObject,
  getMyActiveRfqs,
} from '../selectors';
import { UPDATE_FORM, UPDATE_RFQ_VIEWS, UPDATE_RFQ_DISMISSED } from '../constants';
import { updateForm as actionUpdateForm, updateRFQViews as actionUpdateRFQViews } from '../actions';

const styles = (theme) => ({
  rfqTabBorder: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.dividerAlt,
    backgroundColor: theme.palette.background.innerAlt,
    maxWidth: '320px',
    minWidth: '320px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px',
    padding: '0px 8px',
  },
  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: '1px',
  },
  buttonContainer: {
    width: '132px',
    display: 'flex',
    justifyContent: 'right',
  },
  listTabContainer: {
    overflowY: 'scroll',
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  tabContainer: {
    padding: '8px 0px',
  },
});

class List extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showActive: true,
      startDate: moment().add(-92, 'days'),
      endDate: null,
      sender: '',
      endAllRfqsOpen: false,
      filtersExpanded: false,
    };
  }

  handleChecked = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  filterRFQs = (rfqs) => {
    const { showActive, sender, startDate, endDate } = this.state;
    const { organisationsObject, rfqDismissed } = this.props;

    const filteredRFQs = rfqs
      .filter((item) => {
        if (showActive === true) {
          return item.status === 'ACTIVE';
        }
        return true;
      })
      .filter((item) => {
        if (showActive === true) {
          const index = rfqDismissed.findIndex((n) => n === item.id);
          return index === -1;
        }
        return true;
      })

      .filter((item) => {
        if (!sender) {
          return true;
        }

        return (
          organisationsObject[item.organisationId] &&
          organisationsObject[item.organisationId].shortName === sender
        );
      })
      .filter((item) => {
        if (!startDate) {
          return true;
        }
        return moment(item.createdTime) > moment(startDate).startOf('day');
      })
      .filter((item) => {
        if (!endDate) {
          return true;
        }
        return moment(item.createdTime) < moment(endDate).endOf('day');
      });

    filteredRFQs.sort((a, b) => {
      a = moment(a.createdTime);
      b = moment(b.createdTime);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    return filteredRFQs;
  };

  requestForQuote = () => {
    const payload = {
      type: 'create',
      action: 'open',
    };

    this.props.updateForm(payload);
  };
  updateRfqDismissed = (payload) => {
    this.props.updateRfqViews(payload);
    this.props.updateRfqDismissed(payload);
  };

  viewRfq = (payload) => {
    this.props.tabClick(payload);
    this.props.updateRfqViews({ id: payload });
  };

  render() {
    const { sender, startDate, endDate, showActive, endAllRfqsOpen } = this.state;

    const { classes, senders, rfqs, myActiveRfqs, contacts, userId } = this.props;

    const filteredRfqs = this.filterRFQs(rfqs); // TODO: review to extract into store. NEED REFACTOR. NOT FILTER INTO RENDER

    return (
      <div className={classes.rfqTabBorder}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>ALL OFFERS</div>
          <div className={classes.buttonContainer}>
            <ConfirmButton
              title="CREATE NEW"
              icon="addCircleOutline"
              onClick={this.requestForQuote}
              style={{ width: '100%' }}
            ></ConfirmButton>
          </div>
        </div>

        <div className={classes.listTabContainer}>
          {filteredRfqs.map((rfq) => {
            const selected = this.props.activeRfqId === rfq.id;
            /* let style = { border: 'solid', borderWidth: '.6rem', borderColor: 'transparent' };
            if (
              this.props.activeRfqId !== '' &&
              this.props.activeRfqId !== null &&
              this.props.activeRfqId !== undefined
            ) {
              if (this.props.activeRfqId === rfq.id) {
                style = {
                  border: 'solid',
                  borderWidth: '.6rem',
                  borderColor: fade(amber[300], 0.3),
                };
              }
            } */
            return (
              <div className={classes.tabContainer} key={rfq.id}>
                <Tab
                  key={rfq.id}
                  data={rfq}
                  contacts={contacts}
                  tabClick={() => this.viewRfq(rfq.id)}
                  dismissable
                  viewable
                  updateRfqViews={this.updateRfqDismissed}
                  selected={selected}
                />
              </div>
            );
          })}
        </div>
        <CollapseContainer
          onClick={() =>
            this.setState({
              filtersExpanded: !this.state.filtersExpanded,
            })
          }
          expanded={this.state.filtersExpanded}
          title={'Filters'}
          noFlex
        >
          <div
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <CheckboxField
              accessor="showActive"
              displayName="List Only Active Requests"
              value={showActive}
              handleChecked={this.handleChecked}
              fullWidth
            />
            <SelectField
              accessor="sender"
              displayName="Sender"
              value={sender}
              values={senders}
              handleChange={(name) => (e) => this.setState({ [name]: e.target.value })}
              width={null}
              fullWidth
            />
            <DateField
              accessor="startDate"
              displayName="Start Date"
              value={startDate}
              disablePast={false}
              handleDateChange={(name) => (date) => {
                this.setState({
                  [name]: date,
                });
              }}
              fullWidth
            />
            <DateField
              accessor="endDate"
              displayName="End Date"
              value={endDate}
              disablePast={false}
              handleDateChange={(name) => (date) => {
                this.setState({
                  [name]: date,
                });
              }}
              fullWidth
              clearable
            />
          </div>
        </CollapseContainer>

        <div className={classes.header}>
          <div className={classes.headerTitle}></div>
          <div className={classes.buttonContainer}>
            <CancelButton
              title="END REQUESTS"
              onClick={() => this.setState({ endAllRfqsOpen: true })}
            ></CancelButton>
          </div>
        </div>

        <EndAllRfqs
          userId={userId}
          rfqs={myActiveRfqs}
          open={endAllRfqsOpen}
          close={() => this.setState({ endAllRfqsOpen: false })}
        />
      </div>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    rfqs: getAll(state), // state.orders.rfqs,
    rfqViews: getViews(state),
    rfqDismissed: getDismissed(state),
    myActiveRfqs: getMyActiveRfqs(state),
    userId: getUserId(state), // state.logins.id,
    organisationsObject: getOrganisationsObject(state),
    senders: getSendersRFQs(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (payload) => {
      dispatch(actionUpdateForm(UPDATE_FORM, payload));
    },
    updateRfqViews: (payload) => {
      dispatch(actionUpdateRFQViews(UPDATE_RFQ_VIEWS, payload));
    },
    updateRfqDismissed: (payload) => {
      dispatch(actionUpdateRFQViews(UPDATE_RFQ_DISMISSED, payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(List));
