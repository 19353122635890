import { createSelector } from 'reselect';

import auth from '../../modules/auth';
import organisations from '../../modules/organisations';
import orders from '../../modules/orders';
import users from '../../modules/users';
import workspaces from '../../modules/workspaces';
import contracts from '../../modules/contracts';
import trades from '../../modules/trades';
import chat from '../../modules/chat';
import rfqsModule from '../../modules/rfqs';
import { isObject } from '../../commons/utils/functions';

const getThemesProp = (state) => state.themes;
export const getThemes = createSelector(getThemesProp, (themes) => themes);

const getThemeProp = (state) => state.themes.theme;
export const getTheme = createSelector(getThemeProp, (theme) => theme);

const getThemeTypeProp = (state) => state.themes.type;
export const getThemeType = createSelector(getThemeTypeProp, (type) => type);

const getBlotterSettingsProp = (state) => state.settings.blotter;
export const getBlotterSettings = createSelector(getBlotterSettingsProp, (item) => item);

const getCurrentBlotterSettingsProp = createSelector(getBlotterSettings, (item) => item.current);
export const getCurrentBlotterSettings = createSelector(
  getCurrentBlotterSettingsProp,
  (item) => item,
);

const getInitialBlotterSettingsProp = createSelector(getBlotterSettings, (item) => item.initital);
export const getInitialBlotterSettings = createSelector(
  getInitialBlotterSettingsProp,
  (item) => item,
);

const getDefaultBlotterSettingsProp = createSelector(getBlotterSettings, (item) => item.default);
export const getDefaultBlotterSettings = createSelector(
  getDefaultBlotterSettingsProp,
  (settings) => settings,
);

export const getBlotterAllOrders = createSelector(
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,
  orders.selectors.getAllObject,
  orders.selectors.getIds,
  rfqsModule.selectors.getAllObject,
  (user, users, organisations, byId, ids, rfqsById) => {
    const orders = {
      my: [],
      others: [],
    };

    const existUserOrganisation =
      typeof user.organisationId !== 'undefined' && user.organisationId !== null;

    ids.forEach((id) => {
      const order = byId[id];
      //const data = byId[id].orderData[0];

      order.assets.forEach((data) => {
        const organisation = {};
        const toUser = {};

        const orderGroupId = byId[id].assets.length > 1 ? id : null;

        if (typeof users[order.userId] !== 'undefined') {
          toUser.id = order.userId;
          toUser.name = users[order.userId].firstName;
          toUser.fullName = `${users[order.userId].firstName} ${users[order.userId].lastName}`;
        }

        if (typeof organisations[order.organisationId] !== 'undefined') {
          organisation.id = order.organisationId;
          organisation.name = organisations[order.organisationId].name;
          organisation.shortName = organisations[order.organisationId].shortName;
        }

        const rfqId = order.rfqId;

        let rfqType = 'SPOT';
        if (rfqId !== null) {
          if (isObject(rfqsById[rfqId])) {
            const rfq = rfqsById[rfqId];
            const extensions = JSON.parse(rfq.extensions);
            if (isObject(extensions)) {
              if (extensions.rfqType) {
                rfqType = extensions.rfqType;
              }
            }
          }
        }
        const formatedOrder = {
          id,
          contract: data.contract,
          status: order.status,
          direction: data.direction,
          volume: data.volume,
          originalVolume: data.originalVolume,
          price: data.price,
          recipientList:
            order.visibility === 'SELECTED_ORGANISATIONS'
              ? order.selectedOrganisationsIds.map((orgId) => organisations[orgId])
              : [],
          textMessage: order.textMessage,
          organisation,
          rfqId: order.rfqId,
          rfqType,
          user: toUser,
          orderGroupId,
          // autoClose: order.timeInForce !== null ? 'Yes' : 'No',
          // actingUser: 'actingUser',
          // actingUserName: 'actingUserName',
          // actingUserId: 'actingUserId',
          visibility: order.visibility,
          timeInForce: order.timeInForce,
          timeOfEntry: order.createdTime,
          timeOfUpdate: order.updatedTime,
          tradeId: null,
        };

        if (existUserOrganisation && user.organisationId === formatedOrder.organisation.id) {
          orders.my.push(formatedOrder);
        } else {
          orders.others.push(formatedOrder);
        }
      });
    });

    return orders;
  },
);

export const getBlotterMyTrades = createSelector(
  trades.selectors.getAll,
  auth.selectors.getServerUser,
  users.selectors.getAllObject,
  organisations.selectors.getAllObject,
  orders.selectors.getAllObject,
  rfqsModule.selectors.getAllObject,
  (trades, user, users, orgs, ordersById, rfqsById) => {
    const myOrgId = user.organisationId;
    const formattedTrades = [];

    const getDirection = (direction, buyOrgId) => {
      if (buyOrgId === myOrgId) {
        return direction;
      }
      if (direction === 'SELL') {
        return 'BUY';
      }
      return 'SELL';
    };

    const getFullOrg = (id) => {
      const organisation = {};
      if (typeof orgs[id] !== 'undefined') {
        organisation.id = id;
        organisation.name = orgs[id].name;
        organisation.shortName = orgs[id].shortName;
      }
      return organisation;
    };

    const getFullUser = (id) => {
      const fulluser = {};
      if (typeof users[id] !== 'undefined') {
        fulluser.id = id;
        fulluser.name = `${users[id].firstName} ${users[id].lastName}`;
      }
      return fulluser;
    };

    const getCounterparty = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId === myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };

    const getOwn = (buyOrgId, sellOrgId, sellUserId, buyUserId) => {
      let counterparty = {
        orgId: buyOrgId,
        userId: buyUserId,
      };

      if (buyOrgId !== myOrgId) {
        counterparty = {
          orgId: sellOrgId,
          userId: sellUserId,
        };
      }

      return counterparty;
    };
    const getComments = (buyOrgId, buyOrderId, sellOrderId, ownComments) => {
      if (ownComments) {
        if (buyOrgId === myOrgId) {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        } else {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        }
      } else {
        if (buyOrgId === myOrgId) {
          return ordersById[sellOrderId] ? ordersById[sellOrderId].textMessage : '';
        } else {
          return ordersById[buyOrderId] ? ordersById[buyOrderId].textMessage : '';
        }
      }
    };
    const getRfqType = (rfqId) => {
      let value = 'SPOT';
      if (isObject(rfqsById[rfqId])) {
        const rfq = rfqsById[rfqId];
        const extensions = JSON.parse(rfq.extensions);
        if (isObject(extensions)) {
          if (extensions.rfqType) {
            value = extensions.rfqType;
          }
        }
      }
      return value;
    };

    Object.values(trades).forEach((trade) => {
      const counterpartyIds = getCounterparty(
        trade.buyOrganisationId,
        trade.sellOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const counterpartyCompany = getFullOrg(counterpartyIds.orgId);
      const counterpartyUser = getFullUser(counterpartyIds.userId);
      const ownIds = getOwn(
        trade.buyOrganisationId,
        trade.sellOrganisationId,
        trade.sellUserId,
        trade.buyUserId,
      );
      const ownCompany = getFullOrg(ownIds.orgId);
      const ownUser = getFullUser(ownIds.userId);

      const ownComments = getComments(
        trade.buyOrganisationId,
        trade.buyOrderId,
        trade.sellOrderId,
        true,
      );
      const counterpartyComments = getComments(
        trade.buyOrganisationId,
        trade.buyOrderId,
        trade.sellOrderId,
        false,
      );
      const rfqId = trade.rfqId;
      const rfqComments = isObject(rfqsById[rfqId]) ? rfqsById[rfqId].textMessage : '';
      const rfqType = getRfqType(rfqId);

      trade.assets.forEach((data) => {
        const nominal =
          data.contract.underlying.unit === 'BOX'
            ? parseInt(data.price * data.volume * 20, 10)
            : parseInt(data.price * data.volume, 10);
        const formattedTrade = {
          id: data.id,
          status: trade.status,
          contract: data.contract,
          direction: getDirection(data.direction, trade.buyOrganisationId, user.id),
          volume: data.volume,
          price: data.price,
          counterpartyName: counterpartyCompany.name,
          counterpartyShortName: counterpartyCompany.shortName,
          counterpartyUser: counterpartyUser.name,
          ownName: ownCompany.name,
          ownShortName: ownCompany.shortName,
          ownUser: ownUser.name,
          buyUser: trade.buyUserId,
          buyOrganisation: trade.buyOrganisationId,
          sellUser: trade.sellUserId,
          sellOrganisation: trade.sellOrganisationId,
          tradeGroupId: trade.id,
          rfqId: trade.rfqId,
          rfqType,
          timeStamp: trade.createdTime,
          nominal,
          ownCompany: ownCompany.name,
          counterpartyCompany: counterpartyCompany.name,
          rfqComments,
          bidId: trade.buyOrderId,
          offerId: trade.sellOrderId,
          tradeId: trade.id,
          counterpartyComments,
          ownComments,
        };

        formattedTrades.push(formattedTrade);
      });
    });

    return formattedTrades.filter(
      ({ buyOrganisation, sellOrganisation }) =>
        buyOrganisation === myOrgId || sellOrganisation === myOrgId,
    );
  },
);

const emptyOrganisation = {};
export const getUserFullInfo = createSelector(
  auth.selectors.getServerUser,
  auth.selectors.isConnected,
  organisations.selectors.getAllObject,
  (user, connected, orgs) => {
    const { id: userId, firstName, lastName, organisationId } = user;

    let organisation = null;

    if (Object.prototype.toString.call(orgs[organisationId]) === '[object Object]') {
      const { id, name, shortName, tradingPartnersIds } = orgs[organisationId];

      organisation = {
        id,
        name,
        shortName,
        fullName: name,
        tradingPartnersIds,
      };
    } else {
      organisation = emptyOrganisation;
    }

    const userInfo = {
      userId,
      userName: `${firstName} ${lastName}`,
      fullName: `${firstName} ${lastName}`,
      connected,
      organisation,
    };

    return userInfo;
  },
);

export const getAllWorkspaces = createSelector(workspaces.selectors.getAll, (items) => items);

export const getActiveContract = createSelector(
  contracts.selectors.getActiveContract,
  (item) => item,
);

export const getActiveWorkspace = createSelector(
  workspaces.selectors.getActiveWorkspace,
  (item) => item,
);

export const getAuthStatus = createSelector(auth.selectors.getStatus, (item) => item);

export const isConnected = createSelector(auth.selectors.isConnected, (connected) => connected);

export const getContacts = createSelector(users.selectors.getAll, (contacts) => contacts);
export const getMessages = createSelector(chat.selectors.getAllMessages, (messages) => messages);
export const getNewMessages = createSelector(chat.selectors.getNewMessages, (messages) => messages);

export const getRfqNotifications = createSelector(
  rfqsModule.selectors.getAll,
  rfqsModule.selectors.getViews,
  auth.selectors.getServerUser,
  (rfqs, views, user) => {
    const filteredRFQs = rfqs
      .filter((rfq) => {
        if (Object.prototype.hasOwnProperty.call(rfq, 'createdByUserId')) {
          return rfq.createdByUserId !== user.id;
        }

        return true;
      })
      .filter((rfq) => {
        const index = views.findIndex((n) => n === rfq.id);
        return index === -1;
      })
      .filter((rfq) => rfq.status !== rfqsModule.constants.RFQ_STATUS_ENDED)
      .sort((a, b) => {
        const aUpdatedTime = new Date(a.updatedTime);
        const bUpdatedTime = new Date(b.updatedTime);
        if (aUpdatedTime > bUpdatedTime) {
          return -1;
        }
        return 1;
      });

    return filteredRFQs;
  },
);
