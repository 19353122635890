import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import debounceRender from 'react-debounce-render';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Card } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

import Tab from '../ActiveTab';
import GridHeader from '../GridHeader';
import GridRow from '../GridRow';
import TotalsRow from '../TotalsRow';
import Details from '../Details';
import ActiveGridRow from './ActiveGridRow';
import ActiveGridHeaders from './ActiveGridHeaders';
import Chat from './Chat';
import ResponseDetail from '../ResponseDetail';

import TrafficLights from '../../../../commons/components/trafficLights';
import CheckboxField from '../../../../commons/components/formFields/checkboxField';
import SwitchField from '../../../../commons/components/formFields/switchField';
import { directions, status, avatarSize } from '../../../../commons/models/constants';
import ReadOnlyField from '../../../../commons/components/formFields/readOnlyField';
import UserAvatar from '../../../../commons/components/userAvatar';

import CollapseContainer from '../../../../commons/components/collapseContainer';
import { getEnumerations } from '../../../../modules/contracts/utils/modelFunctions';

import ordersModule from '../../../orders';
import tradesModule from '../../../trades';

import {
  CancelButton,
  ResetButton,
  SaveButton,
  ConfirmButton,
  ClearButton,
} from '../../../../commons/components/buttons';

import {
  getAll,
  getOrganisations,
  getActiveUser,
  getOrders,
  getOrganisationsObject,
  getUsersObject,
} from '../../selectors';

import { UPDATE_RESPONSE_FORM, UPDATE_RFQS, RFQ_STATUS_ENDED } from '../../constants';
import { updateForm, updateRFQs } from '../../actions';
import clsx from 'clsx';
import chatModule from '../../../chat';

const styles = (theme) => ({
  activeRfqContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(),
    backgroundColor: theme.palette.background.innerAlt,
    padding: theme.spacing(),
    border: '1px solid',
    borderColor: theme.palette.dividerAlt,
  },

  activeRfqContent: {
    overflowY: 'scroll',
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '72px',
    padding: '0px 8px',
  },
  headerTitle: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.5,
    letterSpacing: '1px',
  },
  currencyHeader: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 0.9,
  },
  currencyHeaderTitle: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontSize: theme.typography.fontSize * 1.2,
  },
  emptyPrices: {
    textAlign: 'center',
    color: theme.palette.text.hint,
  },
  column: {
    textAlign: 'center',
    fontSize: theme.typography.fontSize,
  },
  bid: {
    color: theme.palette.lightBlue,
    fontSize: theme.typography.fontSize * 1.2,
  },
  offer: {
    color: theme.palette.teal,
    fontSize: theme.typography.fontSize * 1.2,
  },
  volume: {
    fontSize: theme.typography.fontSize * 1.2,
  },
  totalsBid: {
    backgroundColor: theme.palette.background.darkRow,
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    color: '#eee',
    borderCollapse: 'collapse',
    fontSize: theme.typography.fontSize * 1.2,
  },
  totalsOffer: {
    backgroundColor: theme.palette.background.darkRow,
    border: '1px solid',
    borderColor: theme.palette.text.primary,
    color: '#eee',
    fontSize: theme.typography.fontSize * 1.2,
  },
  totalsTitle: {
    textAlign: 'right',
    padding: '8px 16px',
    color: theme.palette.text.secondary,
  },
  tableHeader: {
    color: theme.palette.text.primaryLight,
    fontSize: theme.typography.fontSize * 1.2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  headerBorders: {
    border: 'none',
  },
  placeholder: {
    color: theme.palette.teal,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
});

class Active extends React.PureComponent {
  configStatus = {
    [status.SUSPENDED]: {
      title: 'Orders Suspended',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
        [status.SUSPENDED]: true,
      },
      color: amber[600],
    },
    [status.CANCELLED]: {
      title: 'Orders Cancelled',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
      },
      color: red[500],
    },
    [status.FILLED]: {
      title: '',
      forbidden: {},
    },
    [status.ACTIVE]: {
      title: 'Orders Activated',
      forbidden: {
        [status.FILLED]: true,
        [status.CANCELLED]: true,
        [status.ACTIVE]: true,
      },
      color: teal[300],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      sender: '',
      showActive: false,
      detailsExpanded: false,
      tradesExpanded: true,
      interestsExpanded: false,
      responsesExpanded: false,
      newRfqExpanded: true,
      chatVisible: false,
    };
    this.myRef = React.createRef();
    this.detailsRef = React.createRef();
  }

  getTrades = (activeRfq) => {
    let trades = [];

    if (activeRfq !== null) {
      trades = this.props.trades
        .filter((trade) => Object.prototype.hasOwnProperty.call(trade, 'rfqId'))
        .filter((trade1) => trade1.rfqId === activeRfq.id);
    }
    return trades;
  };

  getTradesByOrderGroup = (trades) => {
    const ids = [];
    const groups = [];

    trades.forEach((t) => {
      if (ids.indexOf(t.tradeGroupId) === -1) {
        ids.push(t.tradeGroupId);
      }
    });

    ids.forEach((id) => {
      const newGroup = trades.filter((trade) => trade.tradeGroupId === id);
      groups.push(newGroup);
    });
    return groups;
  };

  getResponses = (activeRfq) => {
    let responses = [];

    if (activeRfq !== null) {
      const { showActive } = this.state;

      responses = JSON.parse(JSON.stringify(this.props.orders))
        .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
        .filter((order1) => order1.rfqId === activeRfq.id)
        .filter((order2) => order2.direction !== activeRfq.direction)
        .filter((order3) => {
          if (showActive) {
            return (
              order3.status !== status.SUSPENDED &&
              order3.status !== status.CANCELLED &&
              order3.status !== status.FILLED
            );
          }
          return true;
        });
      // if (activeRfq.direction === 'BUY') {
      //   responses.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
      // } else {
      //   responses.sort((a, b) => (a.price < b.price ? 1 : b.price < a.price ? -1 : 0));
      // }
    }

    return responses;
  };

  getInterests = (activeRfq) => {
    let interests = [];

    if (activeRfq !== null) {
      const { showActive } = this.state;

      interests = JSON.parse(JSON.stringify(this.props.orders))
        .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
        .filter((order1) => order1.rfqId === activeRfq.id)
        .filter((order2) => order2.direction === activeRfq.direction)
        .filter((order3) => {
          if (showActive) {
            return (
              order3.status !== status.SUSPENDED &&
              order3.status !== status.CANCELLED &&
              order3.status !== status.FILLED
            );
          }
          return true;
        });

      // if (activeRfq.direction === 'BUY') {
      //   interests.sort((a, b) => (a.price < b.price ? 1 : b.price < a.price ? -1 : 0));
      // } else {
      //   interests.sort((a, b) => (a.price > b.price ? 1 : b.price > a.price ? -1 : 0));
      // }
    }

    return interests;
  };

  getAllInterests = (responses, interests, rfq, user) => {
    const allInterests = {
      sellers: {
        rows: {},
        status: null,
        org: {
          id: null,
          name: null,
          shortName: null,
        },
        user: {
          id: null,
          firstName: null,
          lastName: null,
        },
      },
      buyers: {
        rows: {},
        status: null,
        org: {
          id: null,
          name: null,
          shortName: null,
        },
        user: {
          id: null,
          firstName: null,
          lastName: null,
        },
      },
      compare: [],
    };

    if (rfq) {
      const allOrders = [...responses, ...interests];
      const isMyRfq = user.organisationId === rfq.organisationId;
      allInterests.isMyRfq = isMyRfq;
      /* const isSeller =
        rfq.direction === directions.SELL ? (isMyRfq ? true : false) : isMyRfq ? false : true; */
      const buyers = JSON.parse(JSON.stringify(allOrders))
        .filter((order) => order.direction === 'BUY')
        .filter(
          (order3) => order3.status !== status.CANCELLED && order3.status !== status.FILLED, //&&
          //order3.status !== status.SUSPENDED,
        )
        .sort((a, b) => moment(b.createdTime) - moment(a.createdTime));

      const sellers = JSON.parse(JSON.stringify(allOrders))
        .filter((order) => order.direction === 'SELL')
        .filter(
          (order3) => order3.status !== status.CANCELLED && order3.status !== status.FILLED, //&&
          //order3.status !== status.SUSPENDED,
        )
        .sort((a, b) => moment(b.createdTime) - moment(a.createdTime));

      const weightClasses = this.getWeightClassEnums(rfq);
      let totalVolumeBuyer = 0;
      let totalNotionalBuyer = 0;
      let totalVolumeSeller = 0;
      let totalNotionalSeller = 0;
      weightClasses.map((wgt) => {
        if (buyers[0]) {
          const status = buyers[0].status;
          allInterests.buyers.status = status;
          const org = this.props.organisationsObject[buyers[0].organisationId];
          const { id, name, shortName } = org;
          allInterests.buyers.org = { id, name, shortName };
          const buyUser = this.props.usersObject[buyers[0].userId];
          allInterests.buyers.user = {
            userId: buyUser.id,
            userName: `${buyUser.firstName} ${buyUser.lastName}`,
            organisationName: org.Name,
            organisationShortName: org.shortName,
            organisationId: org.id,
          };
          buyers[0].assets.forEach((a) => {
            if (a.contract.underlying.weightClass === wgt) {
              if (allInterests.buyers.rows[wgt]) {
                const volume = allInterests.buyers.rows[wgt].volume + a.volume;
                let price =
                  allInterests.buyers.rows[wgt].volume * allInterests.buyers.rows[wgt].price +
                  a.price * a.volume;
                price = price / volume;
                allInterests.buyers.rows[wgt] = {
                  price: price,
                  volume: volume,
                  status: status,
                  orderId: buyers[0].id,
                  assetId: a.id,
                  contract: a.contract,
                };
              } else {
                allInterests.buyers.rows[wgt] = {
                  price: a.price,
                  volume: a.volume,
                  status: status,
                  orderId: buyers[0].id,
                  assetId: a.id,
                  contract: a.contract,
                };
              }

              totalVolumeBuyer += a.volume;
              totalNotionalBuyer += a.volume * a.price;
            }

            if (buyers[0].organisationId !== user.organisationId) {
              allInterests.counterpartyInterest = buyers[0];
            } else {
              allInterests.myInterest = buyers[0];
            }
          });
        }

        if (sellers[0]) {
          const status = sellers[0].status;
          allInterests.sellers.status = status;
          const org = this.props.organisationsObject[sellers[0].organisationId];
          const { id, name, shortName } = org;
          allInterests.sellers.org = { id, name, shortName };
          const sellUser = this.props.usersObject[sellers[0].userId];
          allInterests.sellers.user = {
            userId: sellUser.id,
            userName: `${sellUser.firstName} ${sellUser.lastName}`,
            organisationName: org.Name,
            organisationShortName: org.shortName,
            organisationId: org.id,
          };
          sellers[0].assets.forEach((a) => {
            if (a.contract.underlying.weightClass === wgt) {
              if (allInterests.sellers.rows[wgt]) {
                const volume = allInterests.sellers.rows[wgt].volume + a.volume;
                let price =
                  allInterests.sellers.rows[wgt].volume * allInterests.sellers.rows[wgt].price +
                  a.price * a.volume;
                price = price / volume;
                allInterests.sellers.rows[wgt] = {
                  price: price,
                  volume: volume,
                  status: status,
                  orderId: sellers[0].id,
                  assetId: a.id,
                  contract: a.contract,
                };
              } else {
                allInterests.sellers.rows[wgt] = {
                  price: a.price,
                  volume: a.volume,
                  status: status,
                  orderId: sellers[0].id,
                  assetId: a.id,
                  contract: a.contract,
                };
              }

              totalVolumeSeller += a.volume;
              totalNotionalSeller += a.volume * a.price;
            }
          });

          if (sellers[0].organisationId !== user.organisationId) {
            allInterests.counterpartyInterest = sellers[0];
          } else {
            allInterests.myInterest = sellers[0];
          }
        }
      });
      allInterests.sellers.totalVolume = totalVolumeSeller;
      allInterests.sellers.avgPrice =
        totalVolumeSeller !== 0 ? Number(totalNotionalSeller / totalVolumeSeller).toFixed(2) : 0;

      allInterests.buyers.totalVolume = totalVolumeBuyer;
      allInterests.buyers.avgPrice =
        totalVolumeBuyer !== 0 ? Number(totalNotionalBuyer / totalVolumeBuyer).toFixed(2) : 0;
    }

    allInterests.compare = this.compareValues(allInterests, rfq);

    return allInterests;
  };
  compareValues = (allInterests, rfq) => {
    const values = {
      species: {
        value: '',
        warning: false,
      },
      brandName: {
        value: '',
        warning: false,
      },
      deliveryDate: {
        value: '',
        warning: false,
      },
      deliveryPoint: {
        value: '',
        warning: false,
      },
      flightNumber: {
        value: '',
        warning: false,
      },
      consignee: {
        value: '',
        warning: false,
      },
      cartonNetWeight: {
        value: '',
        warning: false,
      },
      incoTerms: {
        value: '',
        warning: false,
      },
      paymentTerms: {
        value: '',
        warning: false,
      },
    };
    const buyer = Object.values(allInterests.buyers.rows)[0];
    const seller = Object.values(allInterests.sellers.rows)[0];

    if (seller !== null && seller !== undefined && seller !== 0) {
      values.species.value = seller.contract.underlying.species;
      values.brandName.value = seller.contract.underlying.brandName;
      values.deliveryDate.value = seller.contract.deliveryDate;
      values.deliveryPoint.value = seller.contract.underlying.deliveryPoint;
      values.flightNumber.value = seller.contract.underlying.flightNumber;
      values.consignee.value = seller.contract.consignee;
      values.cartonNetWeight.value = seller.contract.underlying.cartonNetWeight;
      values.incoTerms.value = seller.contract.underlying.incoTerms;
      values.paymentTerms.value = seller.contract.underlying.paymentTerms;

      if (buyer !== null && buyer !== undefined && buyer !== 0) {
        if (buyer.contract.underlying.species !== seller.contract.underlying.species) {
          values.species.warning = true;
        }
        if (buyer.contract.underlying.brandName !== seller.contract.underlying.brandName) {
          values.brandName.warning = true;
        }
        if (buyer.contract.deliveryDate !== seller.contract.deliveryDate) {
          values.deliveryDate.warning = true;
        }
        if (buyer.contract.underlying.deliveryPoint !== seller.contract.underlying.deliveryPoint) {
          values.deliveryPoint.warning = true;
        }
        if (buyer.contract.underlying.flightNumber !== seller.contract.underlying.flightNumber) {
          values.flightNumber.warning = true;
        }
        if (buyer.contract.consignee !== seller.contract.consignee) {
          values.consignee.warning = true;
        }
        if (
          buyer.contract.underlying.cartonNetWeight !== seller.contract.underlying.cartonNetWeight
        ) {
          values.cartonNetWeight.warning = true;
        }
        if (buyer.contract.underlying.incoTerms !== seller.contract.underlying.incoTerms) {
          values.incoTerms.warning = true;
        }
        if (buyer.contract.underlying.paymentTerms !== seller.contract.underlying.paymentTerms) {
          values.paymentTerms.warning = true;
        }
      }
    } else {
      if (rfq) {
        if (rfq.contract) values.species.value = rfq.contract.underlying.species;
        values.brandName.value = rfq.contract.underlying.brandName;
        values.deliveryDate.value = rfq.contract.deliveryDate
          ? rfq.contract.deliveryDate.from
          : null;
        values.deliveryPoint.value = rfq.contract.underlying.deliveryPoint;
        values.flightNumber.value = rfq.contract.underlying.flightNumber;
        values.consignee.value = rfq.contract.consignee;
        values.cartonNetWeight.value = rfq.contract.underlying.cartonNetWeight;
        values.incoTerms.value = rfq.contract.underlying.incoTerms;
        values.paymentTerms.value = rfq.contract.underlying.paymentTerms;
      }
    }
    return values;
  };
  getWeightClasses = (responses, interests) => {
    const weightClasses = [];

    responses.forEach((rsp) => {
      rsp.assets.forEach((order) => {
        const wgt = order.contract.underlying.weightClass;
        if (weightClasses.indexOf(wgt) === -1) {
          weightClasses.push(wgt);
        }
      });
    });

    interests.forEach((rsp) => {
      rsp.assets.forEach((order) => {
        const wgt = order.contract.underlying.weightClass;
        if (weightClasses.indexOf(wgt) === -1) {
          weightClasses.push(wgt);
        }
      });
    });

    weightClasses.sort();

    if (weightClasses.length === 0) {
      weightClasses.push('');
    }
    return weightClasses;
  };
  getWeightClassEnums = (rfq) => {
    if (rfq) {
      const { product, species, location } = rfq.contract.underlying;
      return getEnumerations('rfq', { product, species, location }).weightClass;
    }
    return [];
  };
  handleSwitch(oldStatus) {
    if (oldStatus === status.SUSPENDED) {
      this.updateAllStatus(status.ACTIVE);
    } else {
      this.updateAllStatus(status.SUSPENDED);
    }
  }

  cancelAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.CANCELLED);
  };

  suspendAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.SUSPENDED);
  };

  activateAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.updateAllStatus(status.ACTIVE);
  };

  // updateAllStatus =(status)=>{

  // TODO: move to saga
  updateAllStatus = (newStatus) => {
    if (!this.configStatus[newStatus]) return;

    const newStatusConfig = this.configStatus[newStatus];

    const { user, orders, activeRfq = {} } = this.props;

    const filteredOrders = orders
      .filter((order) => Object.prototype.hasOwnProperty.call(order, 'rfqId'))
      .filter((order) => order.rfqId === activeRfq.id)
      .filter((order) => order.createdByUserId === user.id)
      .filter((order) => !newStatusConfig.forbidden[order.status]);

    const amendedOrders = [];

    filteredOrders.forEach((order) => {
      const orderId = order.id;
      const orderToAmend = {
        orderId,
        status: newStatus,
        assets: order.assets,
      };
      amendedOrders.push(orderToAmend);
      const payload = { items: [orderToAmend] };
      this.props.updateOrders(payload);
    });

    this.setState({
      snackbarOpen: true,
      snackbarContent: `${newStatusConfig.title}: ${amendedOrders.length}`,
      snackbarColor: newStatusConfig.color,
    });
  };

  addResponseForm = (orders, orderIdToCancel = null, counterpartyContract = null) => {
    const { activeRfq } = this.props;
    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'response',
          action: 'openForm',
          rfq: activeRfq,
          orders,
          orderIdToCancel: orderIdToCancel,
          counterpartyContract,
        };

        this.props.updateResponseForm(payload);
      }
    }
  };
  AddOrAmendResponseForm = (obj) => {
    if (obj.myInterest) {
      if (obj.isMyRfq) {
        this.addResponseForm(obj.myInterest, obj.myInterest.id);
      } else {
        if (obj.counterpartyInterest) {
          this.addResponseForm(
            obj.myInterest,
            obj.myInterest.id,
            obj.counterpartyInterest.assets[0].contract,
          );
        } else {
          this.addResponseForm(obj.myInterest, obj.myInterest.id);
        }
      }
    } else {
      if (obj.isMyRfq) {
        this.addResponseForm(null);
      } else {
        if (obj.counterpartyInterest) {
          this.addResponseForm(obj.counterpartyInterest);
        } else {
          this.addResponseForm(null);
        }
      }
    }
  };

  newAddCounterForm = (obj) => {
    if (obj.counterpartyInterest) {
      if (obj.myInterest) {
        this.addResponseForm(obj.counterpartyInterest, obj.myInterest.id);
      } else {
        this.addResponseForm(obj.counterpartyInterest);
      }
    }
  };

  addTradeForm = (orders, orderIdToCancel = null) => {
    const { activeRfq } = this.props;
    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'execute',
          action: 'openForm',
          rfq: activeRfq,
          orders,
          orderIdToCancel,
        };

        this.props.updateResponseForm(payload);
      }
    }
  };
  newAddTradeForm = (obj) => {
    if (obj.counterpartyInterest) {
      if (obj.myInterest) {
        this.addTradeForm(obj.counterpartyInterest, obj.myInterest.id);
      } else {
        this.addTradeForm(obj.counterpartyInterest);
      }
    }
  };

  addAmendForm = (orders) => {
    const { activeRfq } = this.props;

    if (activeRfq) {
      if (activeRfq.status === 'ACTIVE') {
        const payload = {
          type: 'amend',
          action: 'openForm',
          rfq: activeRfq,
          orders,
        };
        this.props.updateResponseForm(payload);
      }
    }
  };
  newAddAmendForm = (obj) => {
    if (obj.myInterest) {
      this.addAmendForm(obj.myInterest);
    }
  };
  endRFQ = () => {
    const { activeRfq, user } = this.props;

    if (activeRfq.status !== RFQ_STATUS_ENDED) {
      const payload = {
        userId: user.id,
        status: RFQ_STATUS_ENDED,
        rfqs: [activeRfq],
      };

      this.props.endRFQ(payload);
    }
  };

  handleChecked = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  getUserDataFromUserId = (userId, orgId) => {
    const userData = {
      userName: '',
      organisationName: '',
      organisationShortName: '',
      userId,
      organisationId: orgId,
    };

    if (this.props.usersObject[userId]) {
      const user = this.props.usersObject[userId];
      userData.userName = `${user.firstName} ${user.lastName}`;
    }

    if (this.props.organisationsObject[orgId]) {
      const org = this.props.organisationsObject[orgId];
      userData.organisationName = org.name;
      userData.organisationShortName = org.shortName;
    }

    return userData;
  };

  getChat = (room) => {
    const { activeRfq, user } = this.props;

    if (!room.hasOwnProperty('rfqId')) {
      const counterpartyOrgId =
        activeRfq.organisationId === user.organisationId
          ? activeRfq.selectedOrganisationsIds[0]
          : activeRfq.organisationId;

      const originator = user.organisationId;
      const originatorName = this.props.organisationsObject[originator].shortName;
      const counterpartyName = this.props.organisationsObject[counterpartyOrgId].shortName;
      const payload = {
        name: `${originatorName}-${counterpartyName} RFQ-${activeRfq.id}`,
        type: 'RFQ',
        organisationIds: [counterpartyOrgId, originator],
        rfqId: activeRfq.id,
        callback: this.toggleChat,
      };

      this.props.createRfqChat(payload);
    } else {
      this.toggleChat();
    }
  };

  toggleChat = () => {
    if (!this.state.chatVisible) {
      const room = this.getRoom(this.props.chats);
      const payload = {
        action: 'markAsRead',
        items: [{ id: room.id }],
      };
      this.props.updateChats(payload);
    }
    this.setState({ chatVisible: !this.state.chatVisible });
  };
  getRoom = (rooms) => {
    const { activeRfq, user } = this.props;
    let room = {};
    if (activeRfq) {
      const counterpartyOrgId =
        activeRfq.createdByUserId === user.id
          ? activeRfq.selectedOrganisationsIds[0]
          : activeRfq.organisationId;

      room = rooms.find((item) => {
        return item.rfqId === activeRfq.id && item.organisationIds.includes(counterpartyOrgId);
      });
    }

    return room || {};
  };

  render() {
    const { classes, activeRfq, user, contacts } = this.props;
    const interests = this.getInterests(activeRfq);
    const responses = this.getResponses(activeRfq);

    const weightClasses = this.getWeightClasses(responses, interests);
    const weightClasses2 = this.getWeightClassEnums(activeRfq);

    const allInterests = this.getAllInterests(responses, interests, activeRfq, user);
    const orderIdToCancel = allInterests.myInterest ? allInterests.myInterest.id : null;

    const room = this.getRoom(this.props.chats);

    const trades = this.getTrades(activeRfq);
    const tradesByGroup = this.getTradesByOrderGroup(trades);

    const client =
      activeRfq !== null
        ? activeRfq.selectedOrganisationsIds.length > 0
          ? this.props.organisationsObject[activeRfq.selectedOrganisationsIds[0]].shortName
          : ''
        : '';
    const owner =
      activeRfq !== null
        ? activeRfq.selectedOrganisationsIds.length > 0
          ? this.props.organisationsObject[activeRfq.organisationId].shortName
          : ''
        : '';

    let responsesTitle = '';
    let interestsTitle = '';
    if (activeRfq !== null && activeRfq !== undefined) {
      responsesTitle =
        (activeRfq.organisationId === user.organisationId) === true
          ? activeRfq.direction === directions.BUY
            ? 'SELLERS'
            : 'BUYERS'
          : 'MY INTEREST';
      interestsTitle =
        (activeRfq.organisationId === user.organisationId) === true
          ? 'MY INTEREST'
          : activeRfq.direction === directions.BUY
          ? 'BUYERS'
          : 'SELLERS';
    }

    const status = activeRfq !== null ? activeRfq.status : RFQ_STATUS_ENDED;
    const isMyOrder = activeRfq !== null ? activeRfq.createdByUserId === user.id : false;
    const publishIsDisabled = allInterests.myInterest && isMyOrder;
    const myDirection =
      activeRfq !== null
        ? activeRfq.direction === 'BUY'
          ? isMyOrder
            ? 'BUY'
            : 'SELL'
          : isMyOrder
          ? 'SELL'
          : 'BUY'
        : 'BUY';
    const isCompanyOrder =
      activeRfq !== null ? activeRfq.organisationId === user.organisationId : false;

    const species =
      activeRfq !== null
        ? (function (word) {
            const words = word
              .match(/([A-Z]?[^A-Z]*)/g)
              .join(' ')
              .toUpperCase();

            return words;
          })(activeRfq.contract.underlying.species)
        : '';
    const showTradeButton = (function (myDirection, allInterests) {
      if (myDirection === 'BUY') {
        return allInterests.sellers.status === 'ACTIVE';
      } else {
        return allInterests.buyers.status === 'ACTIVE';
      }
    })(myDirection, allInterests);
    return (
      <div ref={this.myRef} className={classes.activeRfqContainer}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>OFFER WINDOW</div>
          <div className={classes.buttonContainer}>
            {/*  {activeRfq !== null && activeRfq !== undefined && (
              <TrafficLights
                cancelAll={this.cancelAll}
                suspendAll={this.suspendAll}
                activateAll={this.activateAll}
                hideOrderButton
              />
            )} */}
          </div>
        </div>
        <div style={{ padding: '4px' }}></div>

        {activeRfq !== null && (
          <Tab
            data={activeRfq}
            contacts={contacts}
            showDetails={() => {
              this.setState({ detailsExpanded: true });
              this.detailsRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
            addOrder={() => this.addResponseForm(null, orderIdToCancel)}
            publishIsDisabled={publishIsDisabled}
          />
        )}
        {activeRfq !== null && (
          <div className={classes.activeRfqContent}>
            {trades.length > 0 && (
              <CollapseContainer
                onClick={() => this.setState({ tradesExpanded: !this.state.tradesExpanded })}
                expanded={this.state.tradesExpanded}
                style={{ backgroundColor: 'transparent' }}
                title="MY TRADES"
              >
                {tradesByGroup.map((t) => {
                  const userData = {
                    userName: t[0].counterpartyUser,
                    organisationName: t[0].counterpartyCompanyName,
                    organisationShortName: t[0].counterpartyCompany,
                    organisationId: t[0].counterpartyOrganisationId,
                  };

                  return (
                    <div
                      key={t[0].id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        onClick={() =>
                          this.props.onTradeClick({ open: true, tradeIds: [t[0].tradeGroupId] })
                        }
                        style={{
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <UserAvatar {...userData} size={avatarSize.SMALL} tooltip />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flex: 10,
                          paddingRight: '4px',
                          paddingBottom: '4px',
                        }}
                      >
                        <TotalsRow rows={t} noColor />
                      </div>
                    </div>
                  );
                })}
                <TotalsRow rows={trades} />
              </CollapseContainer>
            )}

            <CollapseContainer
              onClick={() => this.setState({ newRfqExpanded: !this.state.newRfqExpanded })}
              expanded={this.state.newRfqExpanded}
              style={this.state.newRfqExpanded ? { backgroundColor: 'transparent' } : {}}
              subContainerStyle={this.state.newRfqExpanded ? { height: '4px' } : {}}
            >
              <div style={{ display: 'flex', flexDirection: 'row', padding: 4, flex: 1 }}>
                {activeRfq !== null && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '4px',
                      margin: '0px 32px 0px 0px',
                      justifyContent: 'center',
                    }}
                  >
                    <ReadOnlyField
                      displayName="Species"
                      value={allInterests.compare.species.value}
                      width="152px"
                      dark={!allInterests.compare.species.warning}
                      warning={allInterests.compare.species.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Brand"
                      value={allInterests.compare.brandName.value}
                      width="152px"
                      dark={!allInterests.compare.brandName.warning}
                      warning={allInterests.compare.brandName.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="ETA Date"
                      value={allInterests.compare.deliveryDate.value}
                      width="152px"
                      dark={!allInterests.compare.deliveryDate.warning}
                      warning={allInterests.compare.deliveryDate.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="IncoTerms"
                      value={allInterests.compare.incoTerms.value}
                      width="152px"
                      dark={!allInterests.compare.incoTerms.warning}
                      warning={allInterests.compare.incoTerms.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Delivery Point"
                      value={allInterests.compare.deliveryPoint.value}
                      width="152px"
                      dark={!allInterests.compare.deliveryPoint.warning}
                      warning={allInterests.compare.deliveryPoint.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Flight Number"
                      value={allInterests.compare.flightNumber.value}
                      width="152px"
                      dark={!allInterests.compare.flightNumber.warning}
                      warning={allInterests.compare.flightNumber.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Consignee"
                      value={allInterests.compare.consignee.value}
                      width="152px"
                      dark={!allInterests.compare.consignee.warning}
                      warning={allInterests.compare.consignee.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Carton Size"
                      value={allInterests.compare.cartonNetWeight.value}
                      width="152px"
                      dark={!allInterests.compare.cartonNetWeight.warning}
                      warning={allInterests.compare.cartonNetWeight.warning}
                    ></ReadOnlyField>
                    <ReadOnlyField
                      displayName="Payment Terms"
                      value={allInterests.compare.paymentTerms.value}
                      width="152px"
                      dark={!allInterests.compare.paymentTerms.warning}
                      warning={allInterests.compare.paymentTerms.warning}
                    ></ReadOnlyField>
                  </div>
                )}
                {activeRfq !== null && (
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <ActiveGridHeaders></ActiveGridHeaders>
                      </thead>

                      <tr>
                        <td></td>
                        <td
                          colspan={3}
                          className={clsx(classes.column, classes.volume, classes.headerBorders)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <UserAvatar
                              {...allInterests.sellers.user}
                              //size={avatarSize.SMALL}
                              tooltip
                            />
                          </div>
                        </td>
                        <td></td>

                        <td
                          colspan={3}
                          className={clsx(classes.column, classes.volume, classes.headerBorders)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                            }}
                          >
                            <UserAvatar
                              {...allInterests.buyers.user}
                              //size={avatarSize.SMALL}
                              tooltip
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{species}</td>
                        <td
                          className={clsx(
                            classes.column,
                            classes.tableHeader,
                            classes.headerBorders,
                          )}
                        >
                          <div>Carton</div>
                          <div></div>
                        </td>
                        <td></td>
                        <td
                          className={clsx(
                            classes.column,
                            classes.tableHeader,
                            classes.headerBorders,
                          )}
                        >
                          <div>Offer</div>
                          <div className={classes.currencyHeader}>
                            {' '}
                            {activeRfq ? activeRfq.currency : ''}
                          </div>
                        </td>
                        <td></td>
                        <td
                          className={clsx(
                            classes.column,
                            classes.tableHeader,
                            classes.headerBorders,
                          )}
                        >
                          Carton
                        </td>
                        <td></td>
                        <td
                          className={clsx(
                            classes.column,
                            classes.tableHeader,
                            classes.headerBorders,
                          )}
                        >
                          <div>Bid</div>
                          <div className={classes.currencyHeader}>
                            {activeRfq ? activeRfq.currency : ''}
                          </div>
                        </td>

                        <td>
                          <div className={classes.currencyHeaderTitle}>-</div>
                          <div className={classes.currencyHeader}>RMB</div>
                        </td>
                      </tr>

                      {weightClasses2.map((weightClass, index) => {
                        return (
                          <ActiveGridRow
                            key={weightClass}
                            weightClass={weightClass}
                            index={index}
                            allInterests={allInterests}
                            amend={this.newAddAmendForm}
                          ></ActiveGridRow>
                        );
                      })}
                      <tr></tr>
                      <tr></tr>
                      <tr>
                        <td className={classes.totalsTitle}>TOTALS:</td>
                        <td className={clsx(classes.column, classes.totalsOffer)}>
                          {allInterests.sellers.totalVolume}
                        </td>
                        <td></td>
                        <td className={clsx(classes.column, classes.totalsOffer)}>
                          {allInterests.sellers.avgPrice}
                        </td>
                        <td></td>
                        <td className={clsx(classes.column, classes.totalsBid)}>
                          {allInterests.buyers.totalVolume}
                        </td>
                        <td></td>
                        <td className={clsx(classes.column, classes.totalsBid)}>
                          {allInterests.buyers.avgPrice}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                )}
                {activeRfq !== null && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                      minWidth: '192px',
                    }}
                  >
                    {allInterests.myInterest && (
                      <SwitchField
                        offLabel="LIVE"
                        onLabel="INDICATIVE"
                        handleChecked={() => this.handleSwitch(allInterests.myInterest.status)}
                        checked={allInterests.myInterest.status === 'SUSPENDED'}
                      />
                    )}
                    {showTradeButton && (
                      <ConfirmButton
                        title="TRADE"
                        icon="input"
                        onClick={() => this.newAddTradeForm(allInterests)}
                      ></ConfirmButton>
                    )}
                    <SaveButton
                      title="COUNTER"
                      icon="settings_backup_restore"
                      onClick={() => this.AddOrAmendResponseForm(allInterests)}
                    ></SaveButton>
                    {/*  <ClearButton
                    title="Amend"
                    icon="edit"
                    onClick={() => this.newAddAmendForm(allInterests)}
                  ></ClearButton> */}
                    <ResetButton
                      title="MATCH"
                      icon="content_copy"
                      onClick={() => this.newAddCounterForm(allInterests)}
                    ></ResetButton>
                    <CancelButton
                      title="Cancel"
                      icon="note_add"
                      onClick={() => this.updateAllStatus('CANCELLED')}
                    ></CancelButton>
                    <ClearButton
                      title="Chat"
                      icon="chat_bubble"
                      onClick={() => this.getChat(room)}
                    ></ClearButton>
                  </div>
                )}
              </div>
            </CollapseContainer>
            {this.state.chatVisible && <Chat room={room} chatVisible={this.state.chatVisible} />}
            {activeRfq !== null && (
              <div ref={this.detailsRef}>
                <CollapseContainer
                  onClick={() => this.setState({ detailsExpanded: !this.state.detailsExpanded })}
                  expanded={this.state.detailsExpanded}
                  title={this.state.detailsExpanded ? 'DETAILS' : 'SHOW DETAILS'}

                  /* hasAddedButton={!this.state.detailsExpanded}
              addedButtonTitle="SHOW DETAILS"
              addedButtonIcon="visibility" */
                >
                  <Details activeRfq={activeRfq} />
                </CollapseContainer>
              </div>
            )}
            <CollapseContainer
              onClick={() =>
                this.setState({
                  responsesExpanded: !this.state.responsesExpanded,
                })
              }
              expanded={this.state.responsesExpanded}
              title={responsesTitle}
            >
              <>
                {responses.length === 0 && (
                  <div className={classes.emptyPrices}>No Prices to Show</div>
                )}
                {responses.length > 0 && <GridHeader weightClasses={weightClasses} />}

                {responses.map((i) => {
                  return (
                    <GridRow
                      key={i.id}
                      orderGroup={i}
                      weightClasses={weightClasses}
                      activeRfq={activeRfq}
                      interest={false}
                      addTradeForm={this.addTradeForm}
                      addResponseForm={this.addResponseForm}
                      addAmendForm={this.addAmendForm}
                    />
                  );
                })}
              </>
            </CollapseContainer>

            <CollapseContainer
              onClick={() =>
                this.setState({
                  interestsExpanded: !this.state.interestsExpanded,
                })
              }
              expanded={this.state.interestsExpanded}
              title={interestsTitle}
            >
              <>
                {interests.length === 0 && (
                  <div className={classes.emptyPrices}>No Prices to Show</div>
                )}
                {interests.length > 0 && <GridHeader weightClasses={weightClasses} />}

                {interests.map((i) => {
                  return (
                    <GridRow
                      key={i.id}
                      orderGroup={i}
                      weightClasses={weightClasses}
                      activeRfq={activeRfq}
                      interest
                      addTradeForm={this.addTradeForm}
                      addResponseForm={this.addResponseForm}
                      addAmendForm={this.addAmendForm}
                    />
                  );
                })}
              </>
            </CollapseContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {activeRfq !== null && (
                <div
                  style={{
                    display: 'flex',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CheckboxField
                    accessor="showActive"
                    displayName="Show Only Active Responses?"
                    value={this.state.showActive}
                    handleChecked={this.handleChecked}
                    width="270px"
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {status !== RFQ_STATUS_ENDED && isMyOrder && (
                <CancelButton title="END RFQ" onClick={this.endRFQ}></CancelButton>
                /* <Button variant="contained" color="secondary" onClick={this.endRFQ}>
                  <Icon style={{ paddingRight: '8px' }}>clear</Icon>End Rfq
                </Button> */
              )}
            </div>
          </div>
        )}
        {activeRfq === null && <div className={classes.placeholder}>Choose an Offer to View</div>}
      </div>
    );
  }
}

Active.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    rfqs: getAll(state), // state.orders.rfqs,
    orders: getOrders(state), // state.orders.allOrders,
    organisations: getOrganisations(state), // state.users.organisations,
    contacts: getOrganisations(state), // state.users.organisations,
    user: getActiveUser(state), // state.users,
    organisationsObject: getOrganisationsObject(state),
    usersObject: getUsersObject(state),
    trades: tradesModule.selectors.getBlotterMyTrades(state),
    chats: chatModule.selectors.getChats(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrders: (payload) => {
      dispatch(ordersModule.actions.updateOrders(ordersModule.constants.UPDATE_ORDERS, payload));
    },
    updateResponseForm: (payload) => {
      dispatch(updateForm(UPDATE_RESPONSE_FORM, payload));
    },
    endRFQ: (payload) => {
      dispatch(updateRFQs(UPDATE_RFQS, payload));
    },
    onTradeClick: (payload) => {
      dispatch(
        tradesModule.actions.tradeDetailsOpen(tradesModule.constants.TRADE_DETAILS_OPEN, payload),
      );
    },
    createRfqChat: (payload) => {
      dispatch(chatModule.actions.createRfqChat(payload));
    },
    updateChats: (payload) => {
      dispatch(chatModule.actions.updateChats(chatModule.constants.CHATS_UPDATE, payload));
    },
  };
};

const debouncedActive = debounceRender(withStyles(styles)(Active), 200, { leading: false });

export default connect(mapStateToProps, mapDispatchToProps)(debouncedActive);
