import * as React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
const columns = [
  { id: 'firstName', label: 'FIRST NAME', minWidth: 170 },
  { id: 'lastName', label: 'LAST NAME', minWidth: 100 },
  {
    id: 'title',
    label: 'TITLE',
    minWidth: 170,
    align: 'right',
    format: (value) => value,
  },
  {
    id: 'details',
    label: 'SPECIES',
    minWidth: 170,
    align: 'right',
    format: (value) => {
      const species = value ? (value.species ? value.species : []) : [];
      species.map((s) => <Chip key={s} label={s} />);
    },
  },
  {
    id: 'connectionState',
    label: 'ONLINE',
    align: 'right',
    minWidth: 100,
    format: (value) => {
      const statusColor = value === 'True' ? teal[300] : red[700];
      return <FiberManualRecordIcon style={{ color: statusColor }} />;
    },
  },
];

export default function UserList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const { rows } = props;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card variant="outlined" sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ borderBottomColor: 'rgba(255,255,255,.1)' }}
                      >
                        {column.format && value !== '' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Card>
  );
}
